import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { getRiskProfiles } from "src/redux/api";
import {
    requestRiskProfilesSuccess,
    requestRiskProfilesFailed,
} from "src/redux/actions";

export function* watchRiskProfiles() {
  yield takeEvery(ACTION_TYPES.REQUEST_RISK_PROFILES, requestBenchmarks);
}

function* requestBenchmarks(action) {
  try {
    const riskProfileData = yield call(getRiskProfiles, action.payload);
    yield put(requestRiskProfilesSuccess(riskProfileData));
  } catch (e) {
    yield put(requestRiskProfilesFailed(e));
  }
}
import { ACTION_TYPES } from "src/constants";
import { INITIAL_STATE } from "../store";

export default function AdminGenericLinksReducer(
  state = INITIAL_STATE.adminGenericLinks,
  action
) {
  switch (action.type) {
    case ACTION_TYPES.ADMIN_GENERIC_LINKS_SUCCESS:
      return {
        ...state,
        genericLinksList: action.payload,
      };

    case ACTION_TYPES.ADMIN_CREATE_GENERIC_LINK_CLEAR:
      return {
        ...state,
        createdGenericLink: {},
      };

    case ACTION_TYPES.ADMIN_CREATE_GENERIC_LINK_SUCCESS:
      return {
        ...state,
        createdGenericLink: action.payload,
      };

    case ACTION_TYPES.ADMIN_SHOW_GENERIC_LINK_SUCCESS:
      return {
        ...state,
        showGenericLink: action.payload,
      };

    case ACTION_TYPES.ADMIN_GENERIC_LINK_FAILED:
      return INITIAL_STATE.adminGenericLinks;

    case ACTION_TYPES.REQUEST_ADMIN_LOGIN:
    case ACTION_TYPES.REQUEST_ADMIN_LOGOUT:
    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.adminGenericLinks;

    default:
      return state;
  }
}

import React from "react";
import cx from "classnames";
import styles from "./_button-one.module.scss";

const ButtonOne = (props) => {
  const {
    variant = "one",
    btnType = "default",
    btnSize = "default",
    type = "button",
    className,
    ...otherprops
  } = props;

  return (
    <button
      className={cx(styles["main-btn"], styles[variant], className, {
        [styles[btnType]]: btnType,
        [styles[btnSize]]: btnSize,
      })}
      type={type}
      {...otherprops}
    >
      {props?.children}
    </button>
  );
};

export default ButtonOne;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import EventBus from "eventing-bus";
import { Screen, Loader } from "src/components";
import cx from "classnames";
import { ACTION_TYPES } from "src/constants";
import { ButtonMain } from "src/components/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import styles from "./_admin-login.module.scss";
import { Container, Typography, TextField, Button } from "@mui/material";

import { toast } from "react-toastify";
class AdminLogin extends Component {
  state = {
    loading: false,
    email: "",
    password: "",
    isVisiblePassword: false,
    showVerifyOtp: false,
    otp: "",
  };

  componentDidMount() {
    this.requestSignInSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_LOGIN,
      () => this.setState({ loading: true })
    );
    this.signInSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_LOGIN_SUCCESS,
      this._onLoginSuccess
    );
    this.signInSubscriptionFailure = EventBus.on(
      ACTION_TYPES.ADMIN_LOGIN_FAILED,
      () => this.setState({ loading: false })
    );

    this.requestSignInAxisADSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_AD_LOGIN,
      () => this.setState({ loading: true })
    );
    this.signInAxisADSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_AD_LOGIN_SUCCESS,
      this._onAxisADSuccess
    );
    this.signInAxisADSubscriptionFailure = EventBus.on(
      ACTION_TYPES.ADMIN_AD_LOGIN_FAILED,
      () => this.setState({ loading: false })
    );

    this.requestVerifyOtpSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_VERIFY_OTP,
      () => this.setState({ loading: true })
    );
    this.verifyOtpSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_VERIFY_OTP_SUCCESS,
      this._onVerifyOtpSuccess
    );
    this.verifyOtpSubscriptionFailure = EventBus.on(
      ACTION_TYPES.ADMIN_VERIFY_OTP_FAILED,
      () => this.setState({ loading: false })
    );
  }

  componentWillUnmount() {
    this.requestSignInSubscription();
    this.signInSubscriptionSuccess();
    this.signInSubscriptionFailure();

    this.requestSignInAxisADSubscription();
    this.signInAxisADSubscriptionSuccess();
    this.signInAxisADSubscriptionFailure();

    this.requestVerifyOtpSubscription();
    this.verifyOtpSubscriptionSuccess();
    this.verifyOtpSubscriptionFailure();
  }

  _onChangeEmail = (e) => {
    const email = e.target.value.replace(/[^\w\s@.]/gi, '');
    this.setState({ email: (email && email.trim()) || "" });
  }

  _onChangePassword = (e) => {
    const password = e.target.value;
    this.setState({ password });
  }

  _onChangeOTP = (e) => {
    let otp = e.target.value.replace(/[^0-9]/gi, "");
    otp = otp.slice(0, 6);

    this.setState({ otp: otp });
  }

  _onLoginSuccess = (params) => {
    if(params.otpId) {
      this.setState({ loading: false, showVerifyOtp: true })
    } else {
      this.setState({ loading: false }, () => {
        this.props.history.push("/admin");
      });
    }
  };

  _onAxisADSuccess = (params) => {
    this.setState({loading: false}, () => {
      window.location.href = params.url;
    })
  }

  _onVerifyOtpSuccess = () => {
    this.setState({ loading: false }, () => {
      this.props.history.push("/admin");
    });
  }

  _onPressEnter = (e) => {
    if (e.key === 'Enter') {
      this._onSubmit()
    }
  }

  _onSubmit = () => {
    const { email, password, otp } = this.state;

    if (this.state.showVerifyOtp) {
      this.props.requestAdminVerifyOtp({ email, password, otp });
    } else {
      this.props.requestAdminLogin({ email, password });
    }
  };

  _onSubmitAxisSSO = () => {
    this.props.requestAdminNewADLogin();
  }

  handlePasswordToggle = () => {
    this.setState({ isVisiblePassword: !this.state.isVisiblePassword });
  };

  render() {
    const { isVisiblePassword } = this.state;
    const ssoType = process.env.REACT_APP_SSO_TYPE;

    return (
      <Screen hideHeaderNav>
        <div className={styles["sign-in-container"]}>
          <div className={styles["sign-in-form"]}>
            <div>
              <Typography variant="h5" className={styles['title']} gutterBottom>
                Sign in to the backoffice dashboard
              </Typography>

              <Typography variant="p" className={styles['description']} gutterBottom>
                Please enter your email and password
              </Typography>
            </div>

            <TextField
              label="Email address"
              variant="outlined"
              placeholder="Enter your email address"
              value={this.state.email}
              onChange={this._onChangeEmail}
              disabled={this.state.loading || this.state.showVerifyOtp}
              className={styles["text-field"]}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
            />
            <div className={styles["password-box"]}>
              <TextField
                label="Password"
                variant="outlined"
                className={styles["text-field"]}
                fullWidth
                required
                placeholder="Enter your password"
                value={this.state.password}
                onChange={this._onChangePassword}
                onKeyPress={this._onPressEnter}
                disabled={this.state.loading || this.state.showVerifyOtp}
                type={isVisiblePassword ? "text" : "password"}
                InputLabelProps={{ shrink: true }}
              />
              <div className={styles["password-icon"]}>
                {isVisiblePassword ? (
                  <FontAwesomeIcon
                    icon={faEye}
                    onClick={this.handlePasswordToggle}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    onClick={this.handlePasswordToggle}
                  />
                )}
              </div>
            </div>

            {
              this.state.showVerifyOtp &&
                <TextField
                  label="OTP"
                  variant="outlined"
                  placeholder="Enter OTP"
                  value={this.state.otp}
                  onChange={this._onChangeOTP}
                  disabled={this.state.loading}
                  className={styles['text-field']}
                  onKeyPress={this._onPressEnter}
                  helperText={'The OTP has been sent to your registered phone number and will be valid for 2 minutes'}
                  fullWidth
                  required
                />
            }

            <Button
              type="submit"
              variant="contained"
              className={styles["loginButton"]}
              disabled={!this.state.email || !this.state.password}
              onClick={this._onSubmit}
              fullWidth
            >
 
              Sign In
            </Button>

            {
              ssoType == "AXIS_SAML" &&
                <>
                  <div style={{marginTop: '15px', textAlign: 'center'}}> or </div>
                  <Button
                    type="submit"
                    variant="contained"
                    className={styles["ssoButton"]}
                    onClick={this._onSubmitAxisSSO}
                    fullWidth
                  >
                    Sign in with AXIS SSO
                  </Button>
                </>
            }
            <Loader loading={this.state.loading} />
          </div>
        </div>
      </Screen>
    );
  }
}

export default withRouter(AdminLogin);

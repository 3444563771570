import { ACTION_TYPES } from 'src/constants';

export function requestLead(payload) {
  return {
    type: ACTION_TYPES.REQUEST_LEAD,
    payload,
  };
}

export function leadSuccess(payload) {
  return {
    type: ACTION_TYPES.LEAD_SUCCESS,
    payload,
  };
}

export function leadFailure(payload) {
  return {
    type: ACTION_TYPES.LEAD_FAILURE,
    payload,
  };
}
import { ACTION_TYPES } from 'src/constants';

export function requestCreateMessageTemplate(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_MESSAGE_TEMPLATE,
    payload,
  };
}

export function requestCreateMessageTemplateSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_MESSAGE_TEMPLATE_SUCCESS,
    payload,
  };
}

export function requestCreateMessageTemplateFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_MESSAGE_TEMPLATE_FAILED,
    payload,
  };
}

export function requestUpdateMessageTemplate(payload) {
  return {
    type: ACTION_TYPES.REQUEST_UPDATE_MESSAGE_TEMPLATE,
    payload,
  };
}

export function requestUpdateMessageTemplateSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_UPDATE_MESSAGE_TEMPLATE_SUCCESS,
    payload,
  };
}

export function requestUpdateMessageTemplateFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_UPDATE_MESSAGE_TEMPLATE_FAILED,
    payload,
  };
}

export function requestMessageTemplates(payload) {
  return {
    type: ACTION_TYPES.REQUEST_MESSAGE_TEMPLATES,
    payload,
  };
}

export function requestMessageTemplatesSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_MESSAGE_TEMPLATES_SUCCESS,
    payload,
  };
}

export function requestMessageTemplatesFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_MESSAGE_TEMPLATES_FAILED,
    payload,
  };
}

export function requestGenerateMessageFromTemplate(payload) {
  return {
    type: ACTION_TYPES.REQUEST_GENERATE_MESSAGE_FROM_TEMPLATE,
    payload,
  };
}

export function requestGenerateMessageFromTemplateSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_GENERATE_MESSAGE_FROM_TEMPLATE_SUCCESS,
    payload,
  };
}

export function requestGenerateMessageFromTemplateFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_GENERATE_MESSAGE_FROM_TEMPLATE_FAILED,
    payload,
  };
}
import LandingPages from "./landing-pages";
import { connect } from "react-redux";
import { requestLandingPages } from "src/redux/actions";

const mapStateToProps = (state) => ({
  landingPages: state.landingPages
});

export default connect(mapStateToProps, {
  requestLandingPages
})(LandingPages);

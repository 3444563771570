import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { adminFundsList } from "src/redux/api";
import {
  requestAdminFundsSuccess,
  requestAdminFundsFailed,
} from "src/redux/actions";

export function* watchAdminFundsRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_FUNDS, requestAdminFunds);
}

function* requestAdminFunds(action) {
  try {
    const data = yield call(adminFundsList, action.payload);
    yield put(requestAdminFundsSuccess(data?.funds || []));
  } catch (e) {
    yield put(requestAdminFundsFailed(e));
  }
}

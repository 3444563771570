import React, { Component } from "react";
import { Formik, Form,Field,ErrorMessage } from "formik";
import {
  Dialog, DialogTitle, DialogContent, Container,TextField, Button,
  Avatar, CircularProgress, IconButton, DialogActions
} from "@mui/material";
import { deepOrange } from '@mui/material/colors';
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./_profile-dialog.module.scss";
import * as Yup from 'yup';
import { toast } from "react-toastify";
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import {
  Grid, Autocomplete,
} from "@mui/material";
import Select, { Option, ReactSelectProps } from 'react-select'

class ProfileDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      riskProfile: '',
    };
  }

  componentDidMount() {
    this.updateLeadSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_UPDATE_LEAD,
      () => {
        this.setState({ isLoading: true });
      }
    );

    this.updateLeadSuccessSubscription = EventBus.on(
      ACTION_TYPES.UPDATE_LEAD_SUCCESS,
      () => {
        toast.success("Lead updated!", "success");
        this.setState({ isLoading: false }, this.props.onClose)
      }
    );

    this.updateLeadFailureSubscription = EventBus.on(
      ACTION_TYPES.UPDATE_LEAD_FAILURE,
      () => this.setState({ isLoading: false })
    );
  }

  componentWillUnmount() {
    this.updateLeadSubscription()
    this.updateLeadSuccessSubscription()
    this.updateLeadFailureSubscription()
  }

  handleUpdateLead = (values) => {
    const leadId = this.props.lead.uuid

    const lead = {
      lead: {
        full_name: values?.fullName,
        email: values?.email,
        phone_number: values?.phoneNumber,
        risk_profile_uuid: this.state.riskProfile
      }
    }
    this.props.requestUpdateLead({id : leadId, data : lead});
  };

  validationSchema = Yup.object({
    fullName: Yup.string()
    .required('Name is required')
    .max(50, 'Name must be at most 50 characters')
    .matches(/^[A-Za-z\s()]+$/, 'Name must contain only alphabetic characters, spaces, and parentheses'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    // phoneNumber: Yup.string().matches(/^\d{10}$/, 'Invalid phone number').nullable(),
  });    

  render() {
    let { isOpen, onClose, lead, riskProfiles } = this.props;
    lead ||= {}
    let { fullName } = lead
    fullName ||= ''

    return (
      <Dialog open={isOpen} onClose={onClose} maxWidth='sm'
        fullWidth={true} keepMounted={false}>
        <DialogTitle>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Avatar sx={{ bgcolor: deepOrange[500] }}>{fullName[0]}</Avatar>
            <span style={{marginLeft: 8}}>{fullName}</span>
          </div>
        </DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Formik
            initialValues={{
              fullName: lead.fullName || '',
              email: lead.email || '',
              phoneNumber: lead.phoneNumber || '',
              riskProfile: lead?.riskProfile?.text || ''
            }}
            onSubmit={this.handleUpdateLead}
            validationSchema={this.validationSchema}
          >
            {(formikProps) => (
            <Form>
              <div style={{marginTop: 12}}>
                <Field as={TextField} fullWidth label="Name" name="fullName" />
                <ErrorMessage name="fullName" component="div" className={styles["error-message"]}/>
              </div><br />
              <div>
                <Field as={TextField} fullWidth label="Email ID" name="email" />
                <ErrorMessage name="email" component="div" className={styles["error-message"]} />
              </div><br />
              <div>
                <Field as={TextField} fullWidth label="Phone Number" name="phoneNumber" />
              </div><br />
              <div>
                <Autocomplete
                  options={riskProfiles}
                  getOptionLabel={(option) => option.text}
                  value={riskProfiles.find(profile => profile.text === formikProps.values.riskProfile)}
                  onChange={(event, value) => {
                    formikProps.setFieldValue('riskProfile', value?.text || '');
                    this.setState({ riskProfile: value?.uuid });
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      label="Risk Profile (optional)"
                      {...params}
                    />
                  )}
                />
              </div>
              <div className={styles["done-btn"]}>
                <Button
                  sx={{ width: '40%' }}
                  variant="contained"
                  type="submit"
                  startIcon={this.state.isLoading ? <CircularProgress color="inherit" /> : <SaveIcon />}
                  disabled={this.state.isLoading}
                >
                  SAVE
                </Button>
              </div>
            </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

export default ProfileDialog;

import { ApiRequest } from "src/helpers";
import { API_END_POINTS } from "src/constants";

export function adminWebhooksList() {
  return ApiRequest.fetchAdmin({
    method: "get",
    url: `${API_END_POINTS.ADMIN_WEBHOOKS}`,
  }).then((response) => response.data);
}

export function adminCurrentWebhooks() {
  return ApiRequest.fetchAdmin({
    method: "get",
    url: `${API_END_POINTS.ADMIN_CURRENT_WEBHOOKS}`,
  }).then((response) => response.data);
}

export function adminUpdateWebhook({ data }) {
  return ApiRequest.fetchAdmin({
    method: "put",
    url: `${API_END_POINTS.ADMIN_UPDATE_WEBHOOK}`,
    data,
  }).then((response) => response.data);
}

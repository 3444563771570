import { ACTION_TYPES } from 'src/constants';

export function requestBenchmarks(payload) {
  return {
    type: ACTION_TYPES.REQUEST_BENCHMARKS,
    payload,
  };
}

export function requestBenchmarksSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_BENCHMARKS_SUCCESS,
    payload,
  };
}

export function requestBenchmarksFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_BENCHMARKS_FAILURE,
    payload,
  };
}
import { ACTION_TYPES } from "src/constants";

export function requestBatchUploadList(payload) {
  return {
    type: ACTION_TYPES.REQUEST_BATCH_UPLOAD_LIST,
    payload,
  };
}

export function requestBatchUploadListSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_BATCH_UPLOAD_LIST_SUCCESS,
    payload,
  };
}

export function requestBatchUploadListFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_BATCH_UPLOAD_LIST_FAILURE,
    payload,
  };
}

export function requestBatchUpload(payload) {
  return {
    type: ACTION_TYPES.REQUEST_BATCH_UPLOAD,
    payload,
  };
}

export function requestBatchUploadSuccess(payload) {
  return {
    type: ACTION_TYPES.BATCH_UPLOAD_SUCCESS,
    payload,
  };
}

export function requestBatchUploadFailed(payload) {
  return {
    type: ACTION_TYPES.BATCH_UPLOAD_FAILED,
    payload,
  };
}

export function requestSampleBatchFile(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SAMPLE_BATCH_FILE,
    payload,
  };
}

export function requestSampleBatchFileSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SAMPLE_BATCH_FILE_SUCCESS,
    payload,
  };
}

export function requestSampleBatchFileFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SAMPLE_BATCH_FILE_FAILURE,
    payload,
  };
}

export function requestWhatsappTemplatesList(payload) {
  return {
    type: ACTION_TYPES.REQUEST_WHATSAPP_TEMPLATES_LIST,
    payload,
  };
}

export function requestWhatsappTemplatesListSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_WHATSAPP_TEMPLATES_LIST_SUCCESS,
    payload,
  };
}

export function requestWhatsappTemplatesListFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_WHATSAPP_TEMPLATES_LIST_FAILURE,
    payload,
  };
}
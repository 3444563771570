import { ApiRequest } from "src/helpers";
import { API_END_POINTS } from "src/constants";

export function createMessageTemplate(data) {
  return ApiRequest.fetchAdmin({
    method: "post",
    url: API_END_POINTS.MESSAGE_TEMPLATES,
    data,
  }).then((response) => response.data);
}

export function updateMessageTemplate(data) {
  return ApiRequest.fetchAdmin({
    method: "put",
    url: `${API_END_POINTS.MESSAGE_TEMPLATES}/${data.id}`,
    data,
  }).then((response) => response.data);
}

export function getMessageTemplates(data) {
  return ApiRequest.fetchAdmin({
    method: "get",
    url: API_END_POINTS.MESSAGE_TEMPLATES,
  }).then((response) => response.data);
}

export function generateMessageFromTemplate(data) {
  return ApiRequest.fetchAdmin({
    method: "post",
    url: `${API_END_POINTS.MESSAGE_TEMPLATES}/${data.id}/generate_messages`,
    data
  })
}
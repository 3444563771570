import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { adminWithdrawalList, createAdminSip, adminShowSip } from "src/redux/api";
import {
  requestAdminWithdrawalsListSuccess,
  requestAdminWithdrawalsListFailed,
  // requestAdminCreateSipSuccess,
  // requestAdminCreateSipFailed,
  // requestAdminShowSipSuccess,
  // requestAdminShowSipFailed,
} from "src/redux/actions";

export function* watchAdminWithdrawalsRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_WITHDRAWALS, requestAdminWithdrawalsList);
  // yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_CREATE_SIP, requestAdminCreateSip);
  // yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_SHOW_SIP, requestAdminShowSip);
}

function* requestAdminWithdrawalsList(action) {
  try {
    const withdrawals = yield call(adminWithdrawalList, action.payload);
    yield put(requestAdminWithdrawalsListSuccess(withdrawals));
  } catch (e) {
    yield put(requestAdminWithdrawalsListFailed(e));
  }
}

// function* requestAdminCreateSip(action) {
//   try {
//     const deposits = yield call(createAdminSip, action.payload);
//     yield put(requestAdminCreateSipSuccess(deposits));
//   } catch (e) {
//     yield put(requestAdminCreateSipFailed(e));
//   }
// }

// function* requestAdminShowSip(action) {
//   try {
//     const deposit = yield call(adminShowSip, action.payload);
//     yield put(requestAdminShowSipSuccess(deposit));
//   } catch (e) {
//     yield put(requestAdminShowSipFailed(e));
//   }
// }

import { ACTION_TYPES } from "src/constants";

export function requestAdminAmcs(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_AMCS,
    payload,
  };
}

export function requestAdminAmcsSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_AMCS_SUCCESS,
    payload,
  };
}

export function requestAdminAmcsFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_AMCS_FAILED,
    payload,
  };
}

import { ACTION_TYPES } from "src/constants";
import { INITIAL_STATE } from "../store";

export default function AdminWithdrawalsReducer(
  state = INITIAL_STATE.adminWithdrawals,
  action
) {
  switch (action.type) {
    case ACTION_TYPES.ADMIN_WITHDRAWALS_SUCCESS:
      return {
        ...state,
        withdrawalsList: action.payload,
      };

    case ACTION_TYPES.REQUEST_ADMIN_LOGIN:
    case ACTION_TYPES.REQUEST_ADMIN_LOGOUT:
    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.adminWithdrawals;

    default:
      return state;
  }
}

import { ApiRequest } from "src/helpers";
import { API_END_POINTS } from "src/constants";

export function adminBatchUploadList({}) {
  return ApiRequest.fetchAdmin({
    method: "get",
    url: `${API_END_POINTS.ADMIN_BATCH_UPLOAD_LIST}`,
  }).then((response) => response.data);
}

export function adminUploadBatch({ data }) {
  return ApiRequest.fetchAdmin({
    method: "post",
    url: `${API_END_POINTS.ADMIN_BATCH_UPLOAD}`,
    data,
  }).then((response) => response.data);
}

export function adminDownloadSampleBatchFile({ data }) {
  return ApiRequest.fetchAdmin({
    method: "get",
    url: `${API_END_POINTS.ADMIN_DOWNLOAD_SAMPLE_BATCH_FILE}`,
  }).then((response) => response.data);
}

export function adminWhatsappTemplatesList() {
  return ApiRequest.fetchAdmin({
    method: "get",
    url: `${API_END_POINTS.ADMIN_WHATSAPP_TEMPLATES}`,
  }).then((response) => response.data);
}
import React, { useMemo, useState } from "react";
import moment from "moment";
import { Convert } from "src/helpers";
import { DataTable, TextOne, LinkOne } from "../../../../../components";
import styles from "./_batch-links-table.module.scss";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';

const API_ENDPOINT_URL = process.env.API_ENDPOINT_URL || "https://surface.thesavvyapp.in";

const BatchLinksTable = (props) => {
  const [selectedRowData, setSelectedRowData] = useState(null);
  const tableStyles = {
    bodyRow: {
      backgroundColor: "#ffffff",
      borderBottomColor: "#f3f3f3",
    },
    tableHeader: {
      backgroundColor: "#ffffff",
      borderTop: "1px solid #F3F3F3",
      borderBottom: "1px solid #F3F3F3",
    },
  };

  const { totalItems, data, handlePaginationChange } = props;

  const pageSize = 50;

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };

  const columns = useMemo(
    () => [
      {
        Header: <strong> Upload Name </strong>,
        accessor: "bulkUploadName",
        id: "bulkUploadName",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <div
              onClick={() => setSelectedRowData(row?.original)}
              style={{ cursor: "pointer", width: "100%" }}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: <strong> Started at </strong>,
        accessor: "workerStartedAt",
        disableSortBy: true,
        Cell: ({ row, value }) => (
          <div
            onClick={() => setSelectedRowData(row?.original)}
            style={{ cursor: "pointer", width: "100%" }}
          >
            {value}
          </div>
        ),
      },
      {
        Header: <strong> Status </strong>,
        accessor: "status",
        id: "status",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <div
              onClick={() => setSelectedRowData(row?.original)}
              style={{ cursor: "pointer", width: "100%" }}
            >
              { value == "working" &&
                <SyncIcon style={{color: '#eda12c'}} sx={{
                  animation: "spin 2s linear infinite",
                  "@keyframes spin": {
                    "0%": {
                      transform: "rotate(360deg)",
                    },
                    "100%": {
                      transform: "rotate(0deg)",
                    },
                  },
                }} />
              }
              { value == "completed" &&
                <CheckCircleOutlineTwoToneIcon style={{color: 'green'}}/>
              }
                {value == "finalizing" && " Collating result" }
                {value == "failed" && " Error! Please retry" }
                {value == "expired" && " Process took too long. Please retry" }
            </div>
          );
        },
      },
      {
        Header: <strong> Progress </strong>,
        accessor: "progress",
        id: "progress",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <div
              onClick={() => setSelectedRowData(row?.original)}
              style={{ cursor: "pointer", width: "100%" }}
            >
              {value}%
            </div>
          );
        },
      },
      {
        Header: <strong> Result </strong>,
        accessor: "resultLink",
        id: "resultLink",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <div
              onClick={() => setSelectedRowData(row?.original)}
              style={{ cursor: "pointer", width: "100%" }}
            >
              { value &&
                <a href={value} target="_blank">
                  <DownloadOutlinedIcon
                    alt="download"
                  />
                </a>
              }
            </div>
          );
        },
      },
    ],
    []
  );

  const handleRowData = (row) => {
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        tableStyles={tableStyles}
        paginationConfig={paginationConfig}
        handlePaginationChange={handlePaginationChange}
      />
      <input id="textcopy" className="link-input" style={{ display: "none" }} />
    </>
  );
};

export default BatchLinksTable;

import {
  Dialog, DialogContent, IconButton, FormControl, InputLabel,
  TextField, ToggleButtonGroup, ToggleButton, Select,
  Box, Switch, Grid, Button, Stack, Typography, MenuItem, CircularProgress,
  Autocomplete, Divider, DialogTitle, Input, DialogActions,
} from "@mui/material";
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import { deepOrange } from '@mui/material/colors';
import React, { Component } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import styles from "./create-reminder.module.scss";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import dayjs from "dayjs";
import moment from 'moment';
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CreateMessageTemplate from '../create-message-template'

class CreateReminderDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      reminders: props.currentreminders || [],
      numberOfReminders: 1,
      showNewTemplate: false,
      isTemplatesLoading: false
    }
  }

  componentDidMount() {
    this.createReminderSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_CREATE_LEAD_REMINDER,
      () => {
        this.setState({ isLoading: true });
      }
    );

    this.createReminderSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_CREATE_LEAD_REMINDER_SUCCESS,
      () => {
        toast.success("Reminder creation succeeded!", "success");
        this.setState({ isLoading: false, reminders: [] }, () => this.onClose())
      }
    );

    this.createRecommendationFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_CREATE_LEAD_REMINDER_FAILED,
      () => this.setState({ isLoading: false })
    );

    this.getTemplatesSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_MESSAGE_TEMPLATES,
      () => {
        this.setState({ isTemplatesLoading: true });
      }
    );

    this.getTemplatesSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_MESSAGE_TEMPLATES_SUCCESS,
      () => {
        this.setState({ isTemplatesLoading: false })
      }
    );

    this.getTemplatesdationFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_MESSAGE_TEMPLATES_FAILED,
      () => this.setState({ isTemplatesLoading: false })
    );

    this.props.requestMessageTemplates()
  }

  componentWillUnmount() {
    this.createReminderSubscription()
    this.createReminderSuccessSubscription()
    this.createRecommendationFailureSubscription()

    this.getTemplatesSubscription()
    this.getTemplatesSuccessSubscription()
    this.getTemplatesdationFailureSubscription()
  }

  createNewReminderAt = (index) => {
    const newReminders = [...this.state.reminders]
    newReminders[index] ||= {}

    return newReminders
  }

  handleNumberOfReminders = (numberStr) => {
    this.setState({ numberOfReminders: parseInt(numberStr) });
  };

  handleLeadRecommendationChange = (index, recommendation) => {
    const newReminders = this.createNewReminderAt(index)
    newReminders[index].leadRecommendation = recommendation;

    this.setState({ reminders: newReminders });
  };

  handleAutocompleteChange = (index, lead) => {
    const newReminders = this.createNewReminderAt(index)
    newReminders[index].lead = lead;

    this.setState({ reminders: newReminders });
  };

  handleReminderFrequencyChange = (index, newValue) => {
    const newReminders = this.createNewReminderAt(index)
    newReminders[index].frequency = newValue?.value;

    this.setState({ reminders: newReminders });
  }

  handleReminderDateChange = (date, index) => {
    const newReminders = this.createNewReminderAt(index)
    newReminders[index].deliveryTime = date

    this.setState({ reminders: newReminders });
  }

  handleTemplateChanged = (index, newValue) => {
    const newReminders = this.createNewReminderAt(index)
    newReminders[index].messageTemplate = newValue

    this.setState({ reminders: newReminders });
  }

  generateApiRequest = () => {
    return _.map(this.state.reminders, (reminder) => ({
      lead_uuid: reminder.lead?.uuid,
      lead_recommendation_uuid: reminder.leadRecommendation?.uuid,
      frequency: reminder.frequency,
      delivery_time: reminder.deliveryTime?.format("DD/MM/YYYY HH:mm"),
      message_template_uuid: reminder.messageTemplate?.uuid,
    }))
  }

  handleNewReminderSave = () => {
    const hasEmptyReminder = this.state.reminders.some(
      (reminder) => {
        return (
          !reminder.lead,
          !reminder.leadRecommendation,
          !reminder.frequency,
          !reminder.deliveryTime?.isValid()
        );
      }
    );

    if (hasEmptyReminder || _.isEmpty(this.state.reminders)) {
      this.setState({ allRemindersFieldRequired: true });
      return;
    }

    this.setState({ allRemindersFieldRequired: false });
    
    this.props.requestCreateLeadReminder({
      lead_reminders: {parts: this.generateApiRequest() }
    });
  };

  handleCloseNewTemplate = () => {
    this.setState({showNewTemplate: false})
  }

  onClose = () => {
    this.props.onClose && this.props.onClose()
  }

  render() {
    let { isOpen, onClose, leads, messageTemplates } = this.props;
    const { lead, reminders, numberOfReminders, isTemplatesLoading } = this.state
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Dialog
          open={isOpen}
          onClose={onClose}
          fullWidth={true}
          keepMounted={false}
          maxWidth="sm"
        >
          <DialogTitle>
            Add Reminders
          </DialogTitle>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent dividers>
            <Box className={styles["box-field"]}>
              <Typography style={{fontWeight: '600'}} variant="caption"> Number of reminders: </Typography>
              <ToggleButtonGroup
                value={numberOfReminders}
                exclusive
                onChange={(event) => this.handleNumberOfReminders(event.target.value)}
              >
                { _.times(10, (index) => (
                  <ToggleButton key={index} value={index + 1}> {index + 1} </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>

            <Stack divider={<Divider style={{marginTop: 8, marginBottom: 8}} flexItem />}>
              {_.times(numberOfReminders, (index) => {
                const reminder = reminders[index] || {}
                const lead = reminders[index]?.lead
                const recommendations = lead?.leadRecommendations || []
                const recommendation = reminder.leadRecommendation

                return (
                  <div key={index} sx={{ marginBottom: 2 }}>
                    <div>
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: "Helvetica Neue",
                          fontWeight: 500,
                        }}
                      >{`Reminder ${index + 1}`}</Typography>
                      <Box className={styles["box-field"]}>
                        <Grid
                          container
                          rowSpacing={2.5}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                          <Grid item xs={6}>
                            <Autocomplete
                              fullWidth
                              options={leads}
                              getOptionLabel={(option) => option.fullName}
                              onChange={(event, newValue) =>
                                this.handleAutocompleteChange(
                                  index,
                                  newValue
                                )
                              }
                              renderInput={(params) => (
                                <TextField {...params} label="Lead" />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Autocomplete
                              fullWidth
                              options={recommendations}
                              disabled={!lead}
                              getOptionLabel={(option) => option.fund?.name}
                              onChange={(event, newValue) =>
                                this.handleLeadRecommendationChange(
                                  index,
                                  newValue
                                )
                              }
                              renderInput={(params) => (
                                <TextField {...params} label="Recommendation" />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Autocomplete
                              renderInput={(params) => (
                                <TextField {...params} label="Frequency" />
                              )}
                              disabled={!lead}
                              fullWidth
                              options={[{name: 'One-time', value: 'one_time'}, {name: 'Weekly', value: 'weekly'}, {name: 'Monthly', value: 'monthly'}]}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, newValue) => {
                                this.handleReminderFrequencyChange(index, newValue)}
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DateTimePicker
                              disabled={!lead}
                              sx={{ display: "flex" }}
                              label="Reminder date & time"
                              onChange={ (date) => this.handleReminderDateChange(date, index) }
                            />
                            { reminder.deliveryTime && 
                              <Typography variant="caption" style={{fontWeight: '600'}}>
                                Reminder will be delivered { reminder.frequency }
                                { reminder.frequency == 'weekly' && ` on ${dayjs(reminder.deliveryTime, "DD/MM/YYYY HH:mm").format('dddd')}s` }
                                { reminder.frequency == 'monthly' && ` on the ${moment(reminder.deliveryTime, "DD/MM/YYYY HH:mm").format('Do')} of every month` }
                                { reminder.frequency == 'one-time' && ` on ${reminder.deliveryTime}` }
                                {" "}at { dayjs(reminder.deliveryTime, "DD/MM/YYYY HH:mm").format("hh:mm A") }
                              </Typography>
                            }
                          </Grid>

                          <Grid item xs={9}>
                            <Autocomplete
                              renderInput={(params) => (
                                <TextField {...params} label="Message Template" />
                              )}
                              disabled={!lead || isTemplatesLoading}
                              fullWidth
                              options={messageTemplates}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, newValue) => {
                                this.handleTemplateChanged(index, newValue)}
                              }
                            />
                          </Grid>

                          <Grid item xs={3} style={{display: 'flex'}}>
                            <Button
                              size="small"
                              variant="outlined"
                              style={{fontSize: 9, alignSelf: 'center'}}
                              startIcon={<MapsUgcIcon />}
                              onClick={() => this.setState({showNewTemplate: true})}>
                              New template
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </div>
                  </div>
                );
              })}
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              style={{margin: 8}}
              disabled={this.state.isLoading}
              startIcon={this.state.isLoading ? <CircularProgress size={25} color="inherit" /> : <SaveIcon />}
              onClick={this.handleNewReminderSave}
            >
              Save reminder{numberOfReminders > 1 && `s`}
            </Button>
          </DialogActions>

          {
            this.state.showNewTemplate &&
              <CreateMessageTemplate
                isOpen={this.state.showNewTemplate}
                onClose={this.handleCloseNewTemplate} />
          }
        </Dialog>
      </LocalizationProvider>
    );
  }
}
export default CreateReminderDialog;

import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { adminLogin, adminVerifyOtp, adminLogout, adminNewADLogin, adminVerifyToken } from "src/redux/api";
import {
  adminLoginSuccess,
  adminLoginFailure,
  adminLogoutSuccess,
  adminLogoutFailure,
  adminVerifyOtpSuccess,
  adminVerifyOtpFailure,
  adminVerifyTokenSuccess,
  adminVerifyTokenFailure,
  adminNewADLoginSuccess,
  adminNewADLoginFailure,
} from "src/redux/actions";

export function* watchAdminRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_LOGIN, requestAdminLogin);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_VERIFY_OTP, requestAdminVerifyOtp);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_VERIFY_TOKEN, requestAdminVerifyToken);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_AD_LOGIN, requestAdminNewADLogin);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_LOGOUT, requestAdminLogout);
}

function* requestAdminLogin(action) {
  try {
    const admin = yield call(adminLogin, action.payload);
    yield put(adminLoginSuccess(admin));
  } catch (e) {
    yield put(adminLoginFailure(e));
  }
}

function* requestAdminVerifyOtp(action) {
  try {
    const admin = yield call(adminVerifyOtp, action.payload);
    yield put(adminVerifyOtpSuccess(admin));
  } catch (e) {
    yield put(adminVerifyOtpFailure(e));
  }
}

function* requestAdminVerifyToken(action) {
  try {
    const admin = yield call(adminVerifyToken, action.payload);
    yield put(adminVerifyTokenSuccess(admin));
  } catch (e) {
    yield put(adminVerifyTokenFailure(e));
  }
}

function* requestAdminNewADLogin(action) {
  try {
    const admin = yield call(adminNewADLogin, action.payload);
    yield put(adminNewADLoginSuccess(admin));
  } catch (e) {
    yield put(adminNewADLoginFailure(e));
  }
}

function* requestAdminLogout(action) {
  try {
    const admin = yield call(adminLogout, action.payload)
    yield put(adminLogoutSuccess());
  } catch (e) {
    yield put(adminLogoutFailure(e));
  }
}

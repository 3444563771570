import { ACTION_TYPES } from "src/constants";

/** admin dashboard generic links */

export function requestAdminGenericLinksList(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_GENERIC_LINKS,
    payload,
  };
}

export function requestAdminGenericLinksListSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_GENERIC_LINKS_SUCCESS,
    payload,
  };
}

export function requestAdminGenericLinksListFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_GENERIC_LINKS_FAILED,
    payload,
  };
}

export function requestAdminCreateGenericLink(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_CREATE_GENERIC_LINK,
    payload,
  };
}

export function requestAdminCreateGenericLinkSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_CREATE_GENERIC_LINK_SUCCESS,
    payload,
  };
}

export function requestAdminCreateGenericLinkFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_CREATE_GENERIC_LINK_FAILED,
    payload,
  };
}

export function requestAdminUpdateGenericLink(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_UPDATE_GENERIC_LINK,
    payload,
  };
}

export function requestAdminUpdateGenericLinkSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_UPDATE_GENERIC_LINK_SUCCESS,
    payload,
  };
}

export function requestAdminUpdateGenericLinkFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_UPDATE_GENERIC_LINK_FAILED,
    payload,
  };
}

export function requestAdminCreateGenericLinkClear(payload) {
  return {
    type: ACTION_TYPES.ADMIN_CREATE_GENERIC_LINK_CLEAR,
    payload,
  };
}

export function requestAdminShowGenericLink(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_SHOW_GENERIC_LINK,
    payload,
  };
}

export function requestAdminShowGenericLinkSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_SHOW_GENERIC_LINK_SUCCESS,
    payload,
  };
}

export function requestAdminShowGenericLinkFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_SHOW_GENERIC_LINK_FAILED,
    payload,
  };
}

import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { adminUploadBatch, adminDownloadSampleBatchFile,
  adminBatchUploadList, adminWhatsappTemplatesList } from "src/redux/api";
import {
  requestBatchUploadListSuccess,
  requestBatchUploadListFailed,
  requestBatchUploadSuccess,
  requestBatchUploadFailed,
  requestSampleBatchFileSuccess,
  requestSampleBatchFileFailure,
  requestWhatsappTemplatesListSuccess,
  requestWhatsappTemplatesListFailed
} from "src/redux/actions";

export function* watchAdminBatchUploadRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_BATCH_UPLOAD_LIST, requestBatchUploadList);
  yield takeEvery(ACTION_TYPES.REQUEST_BATCH_UPLOAD, requestBatchUpload);
  yield takeEvery(ACTION_TYPES.REQUEST_SAMPLE_BATCH_FILE, requestBatchFileDownload);
  yield takeEvery(ACTION_TYPES.REQUEST_WHATSAPP_TEMPLATES_LIST, requestWhatsappTemplatesList);
}

function* requestBatchUploadList(action) {
  try {
    const list = yield call(adminBatchUploadList, action.payload);
    yield put(requestBatchUploadListSuccess(list));
  } catch (e) {
    yield put(requestBatchUploadListFailed(e));
  }
}

function* requestBatchUpload(action) {
  try {
    const isBatchUploaded = yield call(adminUploadBatch, action.payload);
    yield put(requestBatchUploadSuccess(isBatchUploaded || false));
  } catch (e) {
    yield put(requestBatchUploadFailed(e));
  }
}

function* requestBatchFileDownload(action) {
  try { 
    const res = yield call(adminDownloadSampleBatchFile, action.payload)
    yield put(requestSampleBatchFileSuccess(res))
  } catch (e) {
    yield put(requestSampleBatchFileFailure(e));
  }
}

function* requestWhatsappTemplatesList(action) {
  try { 
    const res = yield call(adminWhatsappTemplatesList, action.payload)
    yield put(requestWhatsappTemplatesListSuccess(res))
  } catch (e) {
    console.log(e)
    yield put(requestWhatsappTemplatesListFailed(e));
  }
}
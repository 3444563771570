import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {requestToggleMobileSideMenu} from "src/redux/actions";
import cx from 'classnames';
import SideNavigation from './side-navigation';
import styles from './_adminlayout.module.scss';
import Drawer from '@mui/material/Drawer';

const AdminLayout = (props) => {
  const { children } = props;
  const dispatch = useDispatch();

  const showMobileSideMenu = useSelector((state) => state.admin.showMobileSideMenu)

  const customStyles = process.env.REACT_APP_SIDEBAR_COLOR ? { backgroundColor: process.env.REACT_APP_SIDEBAR_COLOR } : {}

  const handleDrawerClose = () => {
    dispatch(requestToggleMobileSideMenu({open: false}))
  }

  return (
      <div
        style={{ paddingLeft: 0, paddingRight: 0, width: '100%' }}
      >
        <div className={styles['layout-container']}>
          <aside className={`${styles.aside} ${styles.toggle}`} style={customStyles}>
            <SideNavigation partner={props.partner} />
          </aside>
          <main className={`${styles.main}`}>
            <div className="admin-screen-animation">{children}</div>
          </main>
        </div>

        <Drawer
          variant="temporary"
          open={showMobileSideMenu}
          onClose={handleDrawerClose}
          ModalProps={{keepMounted: true}}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': customStyles,
          }}
        >
          <SideNavigation partner={props.partner} />
        </Drawer>
      </div>
  );
};

export default AdminLayout;

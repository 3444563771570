import { connect } from "react-redux";
import {
  requestAdminSipList,
  requestAdminCreateSipClear,
  requestAdminShowSip,
} from "src/redux/actions";
import ReadModal from "./read-modal";

const mapStateToProps = (state) => ({
  sipMandate: state.adminSip?.sipList,
  showSip: state.adminSip?.showSip,
});

export default connect(mapStateToProps, {
  requestAdminSipList,
  requestAdminCreateSipClear,
  requestAdminShowSip,
})(ReadModal);

import { connect } from 'react-redux';
import AdminAuthRoute from './admin-auth-route';

function mapStateToProps({ adminCredentials }) {
  const { partnerAccessKey, userIdentityToken } = adminCredentials;

  return {
    partnerAccessKey,
    userIdentityToken,
  };
}

export default connect(mapStateToProps, {})(AdminAuthRoute);

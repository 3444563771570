import { ACTION_TYPES } from "src/constants";

export function requestLandingPages(payload) {
  return {
    type: ACTION_TYPES.REQUEST_LANDING_PAGES,
    payload,
  };
}

export function requestLandingPagesSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_LANDING_PAGES_SUCCESS,
    payload,
  };
}

export function requestLandingPagesFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_LANDING_PAGES_FAILED,
    payload,
  };
}

export function requestCreateLandingPage(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_LANDING_PAGE,
    payload,
  };
}

export function requestCreateLandingPageSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_LANDING_PAGE_SUCCESS,
    payload,
  };
}

export function requestCreateLandingPageFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_LANDING_PAGE_FAILED,
    payload,
  };
}

export function requestUpdateLandingPage(payload) {
  return {
    type: ACTION_TYPES.REQUEST_UPDATE_LANDING_PAGE,
    payload,
  };
}

export function requestUpdateLandingPageSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_UPDATE_LANDING_PAGE_SUCCESS,
    payload,
  };
}

export function requestUpdateLandingPageFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_UPDATE_LANDING_PAGE_FAILED,
    payload,
  };
}

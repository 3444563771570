import { connect } from "react-redux";
import {
  requestAdminSandboxToggle,
} from "src/redux/actions";
import ApiKeys from "./api-keys";

const mapStateToProps = (state) => ({
  apiKeys: state.adminApiKeys,
  sandboxToggle: state.admin?.sandboxToggle,
});

export default connect(mapStateToProps, {
  requestAdminSandboxToggle,
})(ApiKeys);

import React, { Component } from "react";
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import { Loader, Header } from "src/components";
import { Form, Formik } from "formik";
import moment from "moment";
import { TextInputOne } from "src/components/form-inputs";
import { withRouter } from "react-router-dom";
import OneTimeTransactionsTable from "./one-time-transactions-table";
import { Container } from "../../../../components";
import CreateEditOneTimeTransaction from "./create-edit-one-time-transaction";
import GeneratedLink from "./generated-link";
import styles from "./_one-time-transactions.module.scss";
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material'

class OneTimeTransactions extends Component {
  state = {
    isLoading: false,
    pageNumber: 1,
    showCreateEditModal: false,
    showGeneratedLinkModal: false,
    search: "",
  };

  componentDidMount() {
    this.requestDepositsSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_DEPOSITS,
      () => this.setState({ isLoading: true })
    );
    this.depositsSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_DEPOSITS_SUCCESS,
      this.handleDepositSuccess
    );
    this.depositsSubscriptionFailure = EventBus.on(
      ACTION_TYPES.ADMIN_DEPOSITS_FAILED,
      () => this.setState({ isLoading: false })
    );

    this.props.requestAdminDepositList({
      page: this.state.pageNumber,
      checkout_type: "one_time",
    });
  }

  componentWillUnmount() {
    this.requestDepositsSubscription();
    this.depositsSubscriptionSuccess();
    this.depositsSubscriptionFailure();
  }

  handleDepositSuccess = () => {
    this.setState({ isLoading: false });
  };

  _handlePaginationChange = (pageNumber) => {
    this.setState(
      {
        pageNumber,
      },
      () =>
        this.props.requestAdminDepositList({
          page: pageNumber,
          checkout_type: "one_time",
        })
    );
  };

  openCreateEditModal = () => {
    this.setState({ showCreateEditModal: true });
  };

  closeCreateEditModal = () => {
    this.setState({ showCreateEditModal: false });
    this.props.resetFolioList()
  };

  handleOnCompleteCreateDeposit = () => {
    this.setState({ showCreateEditModal: false, showGeneratedLinkModal: true });
    this.props.resetFolioList()
  };

  handleOnCompleteGeneratedLink = () => {
    this.setState({ showGeneratedLinkModal: false }, () => {
      this.props.requestAdminCreateDepositClear();
      this.props.requestAdminDepositList({
        page: this.state.pageNumber,
        checkout_type: "one_time",
      });
    });
  };

  handleSearch = (e) => {
    this.setState({ search: e.target.value.toLowerCase() });
  };

  render() {
    const { isLoading, showCreateEditModal, showGeneratedLinkModal, search } =
      this.state;
    const { onetimeTransactions } = this.props;
    let regex = new RegExp(search, "i");
    const filteredData = (onetimeTransactions?.oneClickCheckouts || []).filter(
      (item) =>
        regex.test(item?.uuid) ||
        regex.test(item?.name.toLowerCase() || "")
    );
    return (
      <div className={styles["one-time-transactions"]}>
        <Header title="Lumpsum Transaction Links" />
        <Container>
          <div className={styles["search-box"]}>
            <Formik initialValues={{ search: "" }}>
              <Form>
                <div className={styles["search-input-box"]}>
                  <TextInputOne
                    name="search"
                    id="search"
                    type="text"
                    value={search}
                    placeholder="Search by entering UUID or User Name....."
                    onChange={this.handleSearch}
                  />
                  <div className={styles["search-icon"]}>
                    <img src="/img/search.svg" alt="search" />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
          <div className={styles["table-data"]}>
            <OneTimeTransactionsTable
              data={filteredData || []}
              handlePaginationChange={this._handlePaginationChange}
              totalItems={onetimeTransactions?.count || 0}
            />
          </div>

          <div className={styles["divider"]} />
          <div className={styles["footer"]}>
            <Button style={{background: 'black'}} variant="contained" startIcon={<AddIcon />} onClick={this.openCreateEditModal}>
              Create Lumpsum Transaction Link
            </Button>
          </div>
        </Container>
        {showCreateEditModal && (
          <CreateEditOneTimeTransaction
            show={showCreateEditModal}
            handleClose={this.closeCreateEditModal}
            handleOnCompleteCreateDeposit={this.handleOnCompleteCreateDeposit}
          />
        )}
        <GeneratedLink
          show={showGeneratedLinkModal}
          handleOnCompleteGeneratedLink={this.handleOnCompleteGeneratedLink}
        />
        <Loader loading={isLoading} />
      </div>
    );
  }
}

export default withRouter(OneTimeTransactions);

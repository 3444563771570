import { ACTION_TYPES } from 'src/constants';

export function requestUpdateLead(payload) {
  return {
    type: ACTION_TYPES.REQUEST_UPDATE_LEAD,
    payload,
  };
}

export function updateLeadSuccess(payload) {
  return {
    type: ACTION_TYPES.UPDATE_LEAD_SUCCESS,
    payload,
  };
}

export function updateLeadFailure(payload) {
  return {
    type: ACTION_TYPES.UPDATE_LEAD_FAILURE,
    payload,
  };
}
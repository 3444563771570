import { ACTION_TYPES } from "src/constants";
import { INITIAL_STATE } from "../store";

export default function AdminDepositsReducer(
  state = INITIAL_STATE.adminDeposits,
  action
) {
  switch (action.type) {
    case ACTION_TYPES.ADMIN_DEPOSITS_SUCCESS:
      return {
        ...state,
        depositsList: action.payload,
      };

    case ACTION_TYPES.ADMIN_CREATE_DEPOSITS_CLEAR:
      return {
        ...state,
        createdDesposit: {},
      };

    case ACTION_TYPES.ADMIN_CREATE_DEPOSITS_SUCCESS:
      return {
        ...state,
        createdDesposit: action.payload,
      };

    case ACTION_TYPES.ADMIN_SHOW_DEPOSIT_SUCCESS:
      return {
        ...state,
        showDeposit: action.payload,
      };

    case ACTION_TYPES.ADMIN_FOLIO_LIST_SUCCESS:
      return {
        ...state,
        folioList: action.payload,
      };

    case ACTION_TYPES.RESET_FOLIO_LIST:
      return {
        ...state,
        folioList: []
      }

    case ACTION_TYPES.ADMIN_DEPOSITS_FAILED:
      return INITIAL_STATE.adminDeposits;

    case ACTION_TYPES.REQUEST_ADMIN_LOGIN:
    case ACTION_TYPES.REQUEST_ADMIN_LOGOUT:
    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.adminDeposits;

    default:
      return state;
  }
}

import React, { Component } from "react";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Convert } from "src/helpers/convert";
import {
  TextInputOne,
  InputSelectOne,
  ButtonOne,
} from "src/components/form-inputs";
import styles from "./_generated-link.module.scss";

class GeneratedLink extends Component {
  onCopyLink = ({ link = "" }) => {
    navigator.clipboard.writeText(link);
    toast.success("Link copied to clipboard", "success");
  };

  render() {
    const { show, createdSip, handleOnCompleteGeneratedLink } = this.props;

    return (
      <div>
        <input id="input-link" className={styles["link-input"]} />
        <Dialog
          open={show}
          fullWidth={true}
          maxWidth="md"
          onClose={handleOnCompleteGeneratedLink}
        >
          <DialogTitle>New Transaction Link Created</DialogTitle>
          <Formik initialValues={{ search: "" }}>
            <Form>
              <div className={styles["content"]}>
                <div className={styles["success-img"]}>
                  <img src="/img/success.svg" alt="success" />
                </div>
                <div className={styles["detail"]}>
                  <div>{createdSip?.oneClickCheckout?.name}</div>
                  <div>
                    {Convert.toCapitalizeLetters(
                      createdSip?.oneClickCheckout?.amcName || ""
                    )}
                  </div>
                  <div>
                    {Convert.toCurrencyValue(
                      createdSip?.oneClickCheckout?.amount || ""
                    )}{" "}
                  </div>
                </div>
                <div className={styles["link"]}>
                  <a href="#">{createdSip?.oneClickCheckout?.shortLink}</a>
                </div>
                <div
                  className={styles["copy-link"]}
                  onClick={() =>
                    this.onCopyLink({
                      link: createdSip?.oneClickCheckout?.shortLink,
                    })
                  }
                >
                  <img src="/img/copylink.svg" alt="copylink" />← Please click
                  to copy this transaction
                </div>
                <div className={styles["footer"]}>
                  <ButtonOne onClick={handleOnCompleteGeneratedLink}>
                    <div className={styles["done"]}>Done</div>
                  </ButtonOne>
                </div>
              </div>
            </Form>
          </Formik>
        </Dialog>
      </div>
    );
  }
}

export default GeneratedLink;

import { ACTION_TYPES } from "src/constants";
import { INITIAL_STATE } from "../store";

export default function AdminAmcsReducer(
  state = INITIAL_STATE.adminAmcs,
  action
) {
  switch (action.type) {
    case ACTION_TYPES.ADMIN_AMCS_SUCCESS:
      return [...action.payload];

    case ACTION_TYPES.ADMIN_AMCS_FAILED:
      return INITIAL_STATE.adminAmcs;

    case ACTION_TYPES.REQUEST_ADMIN_LOGIN:
    case ACTION_TYPES.REQUEST_ADMIN_LOGOUT:
    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.adminAmcs

    default:
      return state;
  }
}

import { connect } from 'react-redux';
import { requestLogout } from 'src/redux/actions';
import Logout from './logout';

function mapStateToProps(state) {
  return {
    user: state.user,
    credentials: state.credentials,
  };
}

export default connect(mapStateToProps, { requestLogout })(Logout);

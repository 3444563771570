import React, { useMemo, useState } from "react";
import moment from "moment";
import { Convert } from "src/helpers";
import { Badge } from "src/components/theme";
import { DataTable, TextOne, LinkOne } from "../../../../../components";
import styles from "./_sip-mandate-table.module.scss";
import ReadModal from "./read-modal";

const API_ENDPOINT_URL = process.env.API_ENDPOINT_URL || "https://surface.thesavvyapp.in";

const SipMandateTable = (props) => {
  const [selectedRowData, setSelectedRowData] = useState(null);

  const tableStyles = {
    bodyRow: {
      backgroundColor: "#ffffff",
      borderBottomColor: "#f3f3f3",
    },
    tableHeader: {
      backgroundColor: "#ffffff",
      borderTop: "1px solid #F3F3F3",
      borderBottom: "1px solid #F3F3F3",
    },
  };

  const { totalItems, data, handlePaginationChange } = props;

  const pageSize = 50;

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };

  const columns = useMemo(
    () => [
      {
        Header: <strong>User Name</strong>,
        accessor: "name",
        id: "name",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setSelectedRowData(row)}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: <strong>Amount</strong>,
        accessor: "amount",
        disableSortBy: true,
        Cell: ({ row, value }) => (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setSelectedRowData(row)}
          >
            {" "}
            {Convert.toCurrencyValue(value || 0)}
          </div>
        ),
      },
      {
        Header: <strong>Status</strong>,
        accessor: "sipStatus",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setSelectedRowData(row)}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: <strong>Expiry date</strong>,
        accessor: "expiry",
        id: "expiry",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <div
              onClick={() => setSelectedRowData(row)}
              style={{ cursor: "pointer", width: "100%" }}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: <strong>UUID</strong>,
        accessor: "uuid",
        id: "uuid",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setSelectedRowData(row)}
            >
              {value.slice(0,25)}...
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        tableStyles={tableStyles}
        paginationConfig={paginationConfig}
        handlePaginationChange={handlePaginationChange}
      />
      {selectedRowData?.uuid && (
        <ReadModal
          show={selectedRowData?.uuid}
          data={selectedRowData}
          handleClose={() => setSelectedRowData(null)}
        />
      )}
    </>
  );
};

export default SipMandateTable;

import { connect } from "react-redux";
import {
  requestAdminSandboxToggle,
} from "src/redux/actions";
import Developers from "./developers";

const mapStateToProps = (state) => ({
  developers: state.adminDevelopers,
  sandboxToggle: state.admin?.sandboxToggle,
});

export default connect(mapStateToProps, {
  requestAdminSandboxToggle,
})(Developers);

import { ACTION_TYPES } from 'src/constants';

export function requestDeleteLead(payload) {
  return {
    type: ACTION_TYPES.REQUEST_DELETE_LEAD,
    payload,
  };
}

export function deleteLeadSuccess(payload) {
  return {
    type: ACTION_TYPES.DELETE_LEAD_SUCCESS,
    payload,
  };
}

export function deleteLeadFailure(payload) {
  return {
    type: ACTION_TYPES.DELETE_LEAD_FAILURE,
    payload,
  };
}
import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import styles from "./create-notes.module.scss";
import {
  Dialog, DialogContent, FormControl, InputLabel,
  TextField, ToggleButtonGroup, ToggleButton, Select,
  Box, Switch, FormGroup, FormControlLabel, Collapse,
  Grid, Button, Stack, Typography, MenuItem, CircularProgress,
  Autocomplete, Divider, Avatar, DialogTitle, Input, DialogActions,
  IconButton
} from "@mui/material";
import { toast } from "react-toastify";
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import _ from 'lodash';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import dayjs from "dayjs";

class CreateNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      numberOfNotes: 1,
      notes: [],
      isKeywordsLoading: false
    };
  }

  componentDidMount() {
    this.createNotesSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_CREATE_NOTES, () => { this.setState({ isLoading: true }) }
    );

    this.createNotesSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_CREATE_NOTES_SUCCESS,
      () => {
        toast.success("Created notes!", "success");
        this.setState({ isLoading: false },
          () => this.props.onClose && this.props.onClose()
        )
      }
    );

    this.createNotesFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_CREATE_NOTES_FAILED, () => this.setState({ isLoading: false })
    );

    this.requestNoteKeywords = EventBus.on(
      ACTION_TYPES.REQUEST_NOTE_KEYWORDS, () => this.setState({isKeywordsLoading: true})
    )

    this.requestNoteKeywordsSucceeded = EventBus.on(
      ACTION_TYPES.REQUEST_NOTE_KEYWORDS_SUCCESS, () => this.setState({isKeywordsLoading: false})
    )

    this.requestNoteKeywordsFailed = EventBus.on(
      ACTION_TYPES.REQUEST_NOTE_KEYWORDS_FAILED, () => this.setState({isKeywordsLoading: false})
    )

    this.props.requestNoteKeywords()
  }

  componentWillUnmount() {
    this.createNotesSubscription()
    this.createNotesSuccessSubscription()
    this.createNotesFailureSubscription()

    this.requestNoteKeywords()
    this.requestNoteKeywordsSucceeded()
    this.requestNoteKeywordsFailed()
  }

  handleNumberOfNotes = (numberStr) => {
    this.setState({ numberOfNotes: parseInt(numberStr) });
  };

  createNewNoteAt = (index) => {
    const newNotes = [...this.state.notes]
    newNotes[index] ||= {}
    if(this.props.lead)
      newNotes[index].lead_uuid = this.props.lead.uuid
    if(!newNotes[index].note_date)
      newNotes[index].note_date = dayjs().format("YYYY-MM-DD");

    return newNotes
  }

  handleDateChange = (date, index) => {
    const newNotes = this.createNewNoteAt(index)

    newNotes[index].note_date = dayjs(date).format("YYYY-MM-DD");
    this.setState({ notes: newNotes });
  };

  handleLeadSelection = (index, newValue) => {
    const newNotes = this.createNewNoteAt(index)

    newNotes[index].lead_uuid = newValue?.uuid;
    this.setState({ notes: newNotes });
  };

  handleKeywordSelection = (index, newValue) => {
    const newNotes = this.createNewNoteAt(index)

    newNotes[index].keyword_uuids = _.map(newValue, 'uuid')
    this.setState({ notes: newNotes });
  }

  handleAdditonalNotesChanged = (index, text) => {
    const newNotes = this.createNewNoteAt(index)

    newNotes[index].note = text

    this.setState({ notes: newNotes })
  }

  handleSaveNotes = () => {
    this.props.requestCreateNotes({
      lead_notes: {
        parts: this.state.notes
      },
    });
  };

  handleClose = (value) => {
    this.setState({ closedNewLead: value });
  };

  render() {
    let { isOpen, onClose, leads, noteKeywords } = this.props;
    noteKeywords = noteKeywords || []
    const { numberOfNotes, notes } = this.state;
    const allFieldsFilled = true

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Dialog open={isOpen}
          onClose={onClose}
          fullWidth={true}
          keepMounted={false}
          maxWidth="sm"
        >
          <DialogTitle>Add New Interactions</DialogTitle>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Box className={styles["box-field"]}>
              <Typography style={{fontWeight: '600'}} variant="caption"> Number of interactions: </Typography>
              <ToggleButtonGroup
                value={numberOfNotes}
                exclusive
                onChange={(event) => this.handleNumberOfNotes(event.target.value)}
              >
                { _.times(10, (index) => (
                  <ToggleButton value={index + 1}> {index + 1} </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>

            <Stack divider={<Divider style={{marginTop: 8, marginBottom: 20}} flexItem />}>
              {_.times(numberOfNotes, (index) => {
                return (
                  <div key={index} sx={{ marginBottom: 2 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Helvetica Neue",
                        fontWeight: 500,
                      }}
                      >{`Note ${index + 1}`}
                    </Typography>
                    <Box>
                      <Grid
                        container
                        rowSpacing={2.5}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        style={{marginTop: '2px'}}
                      >
                        <Grid item xs={6}>
                          <Autocomplete
                            fullWidth
                            options={leads}
                            getOptionLabel={(option) => option.fullName}
                            defaultValue={this.props.lead}
                            disabled={this.props.lead}
                            onChange={(event, newValue) =>
                              this.handleLeadSelection(
                                index,
                                newValue
                              )
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Lead" />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <DatePicker
                            sx={{ display: "flex" }}
                            label="Date"
                            slots={{
                              openPickerIcon:
                                CalendarTodayOutlinedIcon,
                            }}
                            defaultValue={dayjs()}
                            onChange={(date) =>
                              this.handleDateChange(date, index)
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box className={styles["box-field"]}>
                      <Autocomplete
                        fullWidth
                        multiple
                        options={noteKeywords}
                        getOptionLabel={(option) => `${option.keywordText}`}
                        onChange={(event, newValue) =>
                          this.handleKeywordSelection(
                            index,
                            newValue
                          )
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Keywords" />
                        )}
                      />
                    </Box>

                    <Box className={styles["box-field"]}>
                      <TextField
                        fullWidth
                        multiline
                        rows={3}
                        label="Additional notes"
                        onChange={(event) => this.handleAdditonalNotesChanged(index, event.target.value) }
                      />
                    </Box>
                  </div>
                );
              })}

              {!allFieldsFilled && (
                <p style={{ color: "red",display: 'flex',justifyContent: 'center' }}>All fields required</p>
              )}
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              style={{margin: 8}}
              disabled={this.state.isLoading}
              startIcon={this.state.isLoading ? <CircularProgress size={25} color="inherit" /> : <SaveIcon />}
              onClick={this.handleSaveNotes}
            >
              Save interaction{numberOfNotes > 1 && `s`}
            </Button>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
    );
  }
}

export default CreateNotes;

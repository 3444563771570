import { ApiRequest } from "src/helpers";
import { API_END_POINTS } from "src/constants";

export function adminLandingPagesList({ page }) {
  return ApiRequest.fetchAdmin({
    method: "get",
    url: `${API_END_POINTS.ADMIN_LANDING_PAGES}?page=${page}`,
  }).then((response) => response.data);
}

export function createAdminLandingPage(data) {
  return ApiRequest.fetchAdmin({
    method: "post",
    url: API_END_POINTS.ADMIN_LANDING_PAGES,
    data,
  }).then((response) => response.data);
}

export function updateAdminLandingPage(data) {
  return ApiRequest.fetchAdmin({
    method: "put",
    url: `${API_END_POINTS.ADMIN_LANDING_PAGES}/${data.id}`,
    data,
  }).then((response) => response.data);
}
import { connect } from 'react-redux';
import EditReminder from './edit-reminder';
import {
  requestUpdateLeadReminder,
} from "src/redux/actions";

const mapStateToProps = (state) =>  { 
  return {
    leads: state.leads
  }
}

export default connect(mapStateToProps, { 
  requestUpdateLeadReminder
})(EditReminder);
import { ACTION_TYPES } from "src/constants";
import { INITIAL_STATE } from "../store";

export default function BenchmarksReducer(
  state = INITIAL_STATE.benchmarks,
  action
) {
  switch (action.type) {
    case ACTION_TYPES.REQUEST_BENCHMARKS_SUCCESS:
      return action.payload;

    case ACTION_TYPES.REQUEST_ADMIN_LOGOUT:
    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.noteKeywords;

    default:
      return state;
  }
}
import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { getBenchmarks } from "src/redux/api";
import {
    requestBenchmarksSuccess,
    requestBenchmarksFailure,
} from "src/redux/actions";

export function* watchBenchmarks() {
  yield takeEvery(ACTION_TYPES.REQUEST_BENCHMARKS, requestBenchmarks);
}

function* requestBenchmarks(action) {
  try {
    const benchmarksData = yield call(getBenchmarks, action.payload);
    yield put(requestBenchmarksSuccess(benchmarksData));
  } catch (e) {
    yield put(requestBenchmarksFailure(e));
  }
}
import React from 'react'
import cx from 'classnames'
import styles from './_text-one.module.scss'

export const TextOne = (props) => {
  const {
    variant='main',
    semiBold=false,
    block=false,
    bold=false,
    center=false,
    className,
    ...otherProps
  } = props
  return (
    <span className={cx(styles[variant], {
      [styles.semiBold]: semiBold,
      [styles.block]: block,
      [styles.bold]: bold,
      [styles.center]: center,
    },className)} {...otherProps}>
      {props?.text}
      {props?.children}
    </span>
  )
}

import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { deleteLeadApi } from "src/redux/api";
import {
    deleteLeadSuccess,
    deleteLeadFailure,
} from "src/redux/actions";

export function* watchDeleteLeadApi() {
  yield takeEvery(ACTION_TYPES.REQUEST_DELETE_LEAD, requestDeleteLead);
}

function* requestDeleteLead(action) {
  try {
    const deleteLeadData = yield call(deleteLeadApi, action.payload);
    yield put(deleteLeadSuccess(deleteLeadData));
  } catch (e) {
    yield put(deleteLeadFailure(e));
  }
}
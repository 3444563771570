import { connect } from 'react-redux';
import AllInteractionsDialog from './all-interactions-dialog';
import {
} from "src/redux/actions";

const mapStateToProps = (state) =>  {
  return {
  };
}

export default connect(mapStateToProps, { })(AllInteractionsDialog);

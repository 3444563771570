import {
  Dialog, DialogContent, IconButton, FormControl, InputLabel,
  TextField, ToggleButtonGroup, ToggleButton, Select,
  Box, Switch, FormGroup, FormControlLabel, Collapse,
  Grid, Button, Stack, Typography, MenuItem, CircularProgress,
  Autocomplete, Divider, Avatar, DialogTitle, Input, DialogActions,
} from "@mui/material";
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import { deepOrange } from '@mui/material/colors';
import React, { Component } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import styles from "./_add-recommendation-dialog.module.scss";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import dayjs from "dayjs";
import moment from 'moment';
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import _ from 'lodash';

const investmentType = [
  {
    value: "Lumpsum",
    label: "Lumpsum",
  },
  {
    value: "Sip",
    label: "Sip",
  },
];

const initialState = {
  isRecommendationsShown: false,
  isNextBtnChange: false,
  numberOfRecommendations: 1,
  recommendations: [],
  recommendationObjects: [],
  allRecommendationsFieldRequired: false,
  amountError: false,
  isLoading: false,
};

class AddRecommendationDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      recommendations: props.currentRecommendations || [],
      numberOfRecommendations: _.size(props.currentRecommendations) || 1,
      isFundsLoading: false,
      fundData: []
    }
  }

  componentDidMount() {
    this.fundSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_FUNDS,
      () => {
        this.setState({ isFundsLoading: true });
      }
    );

    this.fundSuccessSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_FUNDS_SUCCESS,
      (res) => {
        const { fundData } = this.state;

        if(_.isEmpty(res)) {
          this.setState({isFundsLoading: false});
        } else {
          const newObject = {}
          newObject[res[0].amcCode] = res
          this.setState({
            fundData: {...this.state.fundData, ...newObject},
            isFundsLoading: false,
          });
        }
      }
    );

    this.fundFailureSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_FUNDS_FAILED,
      () => this.setState({ isFundsLoading: false })
    );

    this.createRecommendationSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_CREATE_LEAD_RECOMMENDATIONS,
      () => {
        this.setState({ isLoading: true });
      }
    );

    this.createRecommendationSuccessSubscription = EventBus.on(
      ACTION_TYPES.CREATE_LEAD_RECOMMENDATIONS_SUCCESS,
      () => {
        toast.success("Recommendation creation succeeded!", "success");
        this.setState({ isLoading: false, recommendations: [] }, () => this.onClose())
      }
    );

    this.createRecommendationFailureSubscription = EventBus.on(
      ACTION_TYPES.CREATE_LEAD_RECOMMENDATIONS_FAILURE,
      () => this.setState({ isLoading: false })
    );

    this.createLeadSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_CREATE_LEADS,
      () => {
        this.setState({ isLoading: true });
      }
    );

    this.createLeadSuccessSubscription = EventBus.on(
      ACTION_TYPES.CREATE_LEADS_SUCCESS,
      () => {
        toast.success("Lead creation succeeded!", "success");
        this.setState({ isLoading: false, recommendations: [] }, () => this.onClose())
      }
    );

    this.createLeadFailureSubscription = EventBus.on(
      ACTION_TYPES.CREATE_LEADS_FAILURE,
      () => this.setState({ isLoading: false })
    );

    this.getBenchmarksSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_BENCHMARKS,
      () => {
        this.setState({ isBenchmarksLoading: true });
      }
    );

    this.getBenchmarksSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_BENCHMARKS_SUCCESS,
      () => {
        this.setState({ isBenchmarksLoading: false })
      }
    );

    this.getBenchmarksFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_BENCHMARKS_FAILURE,
      () => this.setState({ isBenchmarksLoading: false })
    );

    this.props.requestBenchmarks()
  }

  componentWillUnmount() {
    this.createRecommendationSubscription()
    this.createRecommendationSuccessSubscription()
    this.createRecommendationFailureSubscription()

    this.createLeadSubscription()
    this.createLeadSuccessSubscription()
    this.createLeadFailureSubscription()

    this.getBenchmarksSubscription()
    this.getBenchmarksSuccessSubscription()
    this.getBenchmarksFailureSubscription()
  }

  createNewRecommendationAt = (index) => {
    const newRecommendations = [...this.state.recommendations]
    newRecommendations[index] ||= {recommendationDate: dayjs(), amount: '10000'}

    return newRecommendations
  }

  handleNumberOfRecommendations = (numberStr) => {
    this.setState({ numberOfRecommendations: parseInt(numberStr) });
  };

  handleInvestmentTypeChange = (index, investmentType) => {;
    const newRecommendations = this.createNewRecommendationAt(index)
    newRecommendations[index].investmentType = investmentType;

    this.setState({ recommendations: newRecommendations });
  }

  handleBenchmarkChange = (index, benchmark) => {
    const newRecommendations = this.createNewRecommendationAt(index)
    newRecommendations[index].benchmark = this.props.benchmarks[1];

    this.setState({ recommendations: newRecommendations });
  };

  handleDateChange = (date, index) => {
    const newRecommendations = this.createNewRecommendationAt(index)
    newRecommendations[index].recommendationDate = date

    this.setState({ recommendations: newRecommendations });
  };

  handleAMCAutocompleteChange = (index, amc) => {
    const newRecommendations = this.createNewRecommendationAt(index)
    newRecommendations[index].amc = amc;
    newRecommendations[index].fund = null;

    this.setState({ recommendations: newRecommendations });
    if(amc) {
      const selectedFunds = this.state.fundData[amc.code]
      if(_.isEmpty(selectedFunds)) {
        this.props.requestAdminFunds({ amc_code: amc.code });
      }
    }
  }

  handleAutocompleteChange = (index, fund) => {
    const newRecommendations = this.createNewRecommendationAt(index)
    newRecommendations[index].fund = fund;

    this.setState({ recommendations: newRecommendations });
  };

  handleAmountChange = (event, index) => {
    event.target.value = event.target.value.replace(/^0+|\D+/g, "").slice(0, 8);
    const amount = event.target.value;

    const newRecommendations = this.createNewRecommendationAt(index)
    newRecommendations[index].amount = amount;

    this.setState({ recommendations: newRecommendations });
  };

  handleReminderBtn = (index) => {
    const newRecommendations = [...this.state.recommendations]
    newRecommendations[index] ||= {}
    if(newRecommendations[index].reminders)
      newRecommendations[index].reminders = null
    else
      newRecommendations[index].reminders ||= [{ frequency: "one_time", delivery_time: null }]
    
    this.setState({ recommendations: newRecommendations });
  };

  handleReminderFrequencyChange = (event, index) => {
    const newRecommendations = this.createNewRecommendationAt(index)
    newRecommendations[index].reminders[0].frequency = event.target.value;

    this.setState({ recommendations: newRecommendations });
  }

  handleReminderDateChange = (date, index) => {
    const newRecommendations = this.createNewRecommendationAt(index)
    newRecommendations[index].reminders[0].deliveryTime = date

    this.setState({ recommendations: newRecommendations });
  }

  generateApiRequest = () => {
    return _.map(this.state.recommendations, (rec) => ({
      investment_type: rec.investmentType,
      benchmark_uuid: rec.benchmark?.uuid,
      recommendation_date: rec.recommendationDate.format("DD/MM/YYYY"),
      fund_code: rec.fund?.code,
      amount: rec.amount,
      reminders: rec.reminders && rec.reminders[0] ? [{
        frequency: rec.reminders[0].frequency,
        delivery_time: rec.reminders[0].deliveryTime.format("DD/MM/YYYY HH:mm"),
      }] : []
    }))
  }

  handleNewRecommendationSave = () => {
    const hasEmptyRecommendation = this.state.recommendations.some(
      (recommendation) => {
        return (
          !recommendation.recommendationDate?.isValid() ||
          !recommendation.amount ||
          !recommendation.fund
        );
      }
    );

    if (hasEmptyRecommendation || _.isEmpty(this.state.recommendations)) {
      this.setState({ allRecommendationsFieldRequired: true });
      return;
    }

    this.setState({ allRecommendationsFieldRequired: false });
    if(this.props.id)
      this.props.requestCreateLeadRecommendations({
        lead_recommendations: {
          lead_id: this.props.id,
          lead_recommendations_parts: this.generateApiRequest(),
        },
      });
    else if(this.props.newLead) {
      this.props.onSave(this.state.recommendations)
    }
  };

  onClose = () => {
    this.setState(initialState, () => this.props.onClose && this.props.onClose())
  }

  render() {
    let { isOpenDialog, onClose, name, adminFunds, adminAmcs, benchmarks } = this.props;
    name ||= ""
    const {
      numberOfRecommendations,
      isRecommendationsShown,
      isNextBtnChange,
      recommendations,
      allRecommendationsFieldRequired,
      amountError,
    } = this.state;

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Dialog
          open={isOpenDialog}
          onClose={onClose}
          fullWidth={true}
          keepMounted={false}
          maxWidth="sm"
        >
          <DialogTitle>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Avatar sx={{ bgcolor: deepOrange[500] }}>{name[0]}</Avatar>
              <span style={{marginLeft: 8}}>Add recommendations for {name}</span>
            </div>
          </DialogTitle>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent dividers>
            <Box className={styles["box-field"]}>
              <Typography style={{fontWeight: '600'}} variant="caption"> Number of recommendations: </Typography>
              <ToggleButtonGroup
                value={numberOfRecommendations}
                exclusive
                onChange={(event) => this.handleNumberOfRecommendations(event.target.value)}
              >
                { _.times(10, (index) => (
                  <ToggleButton key={index} value={index + 1}> {index + 1} </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>

            <Stack divider={<Divider style={{marginTop: 8, marginBottom: 8}} flexItem />}>
              {_.times(numberOfRecommendations, (index) => {
                const reminder = _.head(recommendations[index]?.reminders) || {}
                const recommendation = recommendations[index] || {}
                const amc = recommendation.amc
                let funds = []
                if(amc)
                  funds = this.state.fundData[amc.code] || []
                const fund = recommendation.fund
                const comparisons = fund?.comparisons || []

                return (
                  <div key={index} sx={{ marginBottom: 2 }}>
                    <div>
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: "Helvetica Neue",
                          fontWeight: 500,
                        }}
                      >{`Recommendation ${index + 1}`}</Typography>
                      <Grid container spacing={2} style={{marginTop: '6px'}} className={styles["box-field"]}>
                        <Grid item xs={4}>
                          <Autocomplete
                            fullWidth
                            options={adminAmcs}
                            getOptionLabel={(option) => option.name}
                            value={recommendation.amc}
                            onChange={(event, newValue) =>
                              this.handleAMCAutocompleteChange(
                                index,
                                newValue
                              )
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="AMC" />
                            )}
                          />
                        </Grid>

                        <Grid item xs={8}>
                          <Autocomplete
                            fullWidth
                            options={funds}
                            getOptionLabel={(option) => option.name}
                            value={recommendation.fund}
                            onChange={(event, newValue) =>
                              this.handleAutocompleteChange(
                                index,
                                newValue
                              )
                            }
                            disabled={this.state.isFundsLoading}
                            renderInput={(params) => (
                              <TextField {...params} label={this.state.isFundsLoading ? "Loading..." : "Fund Name"} />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <Box>
                      <Grid
                        container
                        rowSpacing={2.5}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item xs={6}>
                          <Autocomplete
                            fullWidth
                            options={comparisons}
                            getOptionLabel={(option) => `${option.name}, ${option.return3Year}% 3 year return`}
                            // value={recommendation.name}
                            onChange={(event, newValue) =>
                              this.handleBenchmarkChange(
                                index,
                                newValue
                              )
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Benchmark" />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <DatePicker
                            sx={{ display: "flex" }}
                            label="Date"
                            slots={{
                              openPickerIcon: CalendarTodayOutlinedIcon,
                            }}
                            value={recommendation.recommendationDate}
                            onChange={(date) =>
                              this.handleDateChange(date, index)
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            select
                            label="Investment Type"
                            value={recommendation.investmentType}
                            onChange={(event) => {
                              this.handleInvestmentTypeChange(index, event.target.value)
                            }}
                          >
                            {investmentType.map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="Amount"
                            value={recommendation.amount}
                            error={amountError}
                            InputLabelProps={{ shrink: recommendation.amount }}
                            onChange={(event) => {
                              this.handleAmountChange(event, index);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <FormGroup>
                      <FormControlLabel control={
                          <Switch checked={recommendations[index]?.reminder} onChange={() => this.handleReminderBtn(index)}/>
                        }
                        label="Add Reminder"
                         style={{display: 'flex', justifyContent: 'flex-end'}}
                      />
                    </FormGroup>
                    <Collapse in={recommendations[index]?.reminders}>
                      <Box className={styles["reminder-box"]}>
                        <Typography variant="body2" style={{fontWeight: '600', textAlign: 'center'}}>
                          Create reminder for recommendation {index + 1}
                        </Typography>
                        <br />
                        <FormControl fullWidth>
                          <InputLabel id="select-label">Reminder frequency</InputLabel>
                          <Select
                            labelId="select-label"
                            id="simple-select"
                            value={reminder.frequency || 'one_time'}
                            label="Reminder frequency"
                            onChange={(value) => this.handleReminderFrequencyChange(value, index)}
                          >
                            <MenuItem value={'one_time'}>One-time</MenuItem>
                            <MenuItem value={'weekly'}>Weekly</MenuItem>
                            <MenuItem value={'monthly'}>Monthly</MenuItem>
                          </Select>
                        </FormControl>
                        <br /> <br />
                        <DateTimePicker
                          sx={{ display: "flex" }}
                          label="Reminder date & time"
                          onChange={ (date) => this.handleReminderDateChange(date, index) }
                        />
                        { reminder.delivery_time && 
                          <Typography variant="caption" style={{fontWeight: '600'}}>
                            Reminder will be delivered { reminder.frequency }
                            { reminder.frequency == 'weekly' && ` on ${dayjs(reminder.delivery_time, "DD/MM/YYYY HH:mm").format('dddd')}s` }
                            { reminder.frequency == 'monthly' && ` on the ${moment(reminder.delivery_time, "DD/MM/YYYY HH:mm").format('Do')} of every month` }
                            { reminder.frequency == 'one_time' && ` on ${reminder.delivery_time}` }
                            {" "}at { dayjs(reminder.delivery_time, "DD/MM/YYYY HH:mm").format("hh:mm A") }
                          </Typography>
                        }
                      </Box>
                    </Collapse>
                  </div>
                );
              })}

              {allRecommendationsFieldRequired && (
                <p style={{ color: "red",display: 'flex',justifyContent: 'center' }}>All fields required</p>
              )}
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              style={{margin: 8}}
              disabled={this.state.isLoading}
              startIcon={this.state.isLoading ? <CircularProgress color="inherit" /> : <SaveIcon />}
              onClick={this.handleNewRecommendationSave}
            >
              Save Recommendation{numberOfRecommendations > 1 && `s`}
            </Button>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
    );
  }
}
export default AddRecommendationDialog;

import React, { useMemo, useState } from "react";
import moment from "moment";
import { Convert } from "src/helpers";
import { DataTable } from "../../../../../components";
import { GridToolbar } from '@mui/x-data-grid';
import List from '@mui/material/List';
import Popover from '@mui/material/Popover';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import RecommendIcon from '@mui/icons-material/Recommend';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { deepOrange, deepPurple } from '@mui/material/colors';
import Collapse from '@mui/material/Collapse';
import EditReminder from "../edit-reminder";
import _ from 'lodash';

const ReminderDashboardTable = (props) => {
  const [selectedEditRow, setSelectedEditRow] = useState(null)
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [selectedMore, setSelectedMore] = useState(null);
  const [selectedMoreAnchor, setSelectedMoreAnchor] = useState(null);
  const tableStyles = {
    bodyRow: {
      backgroundColor: "#ffffff",
      borderBottomColor: "#f3f3f3",
    },
    tableHeader: {
      backgroundColor: "#ffffff",
      borderTop: "1px solid #F3F3F3",
      borderBottom: "1px solid #F3F3F3",
    },
  };

  const { totalItems, data, handlePaginationChange } = props;

  const pageSize = 50;

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };

  const columns =
    [
      {
        Header: <strong>User Name</strong>,
        accessor: "lead",
        id: "name",
        flex: 0.5,
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <div style={{ cursor: "pointer", width: "100%" }}>
              {value.fullName}
            </div>
          );
        },
      },
      {
        Header: <strong>Recommendation</strong>,
        accessor: "leadRecommendation",
        disableSortBy: true,
        flex: 0.8,
        Cell: ({ row, value }) => {
          return (
            <div style={{ cursor: "pointer", width: "100%" }}>
              {value?.fund?.name}
            </div>
          )
        },
      },
      {
        Header: <strong>Reminder details</strong>,
        accessor: "uuid",
        disableSortBy: true,
        flex: 1,
        Cell: ({ row, value }) => {
          const deliveryTime = row.deliveryTime ? moment(row.deliveryTime, 'DD/MM/YYYY HH-mm-ss') : null
          return(
            <div style={{ cursor: "pointer", width: "100%" }}>
              {deliveryTime ?
                `${Convert.toTitleCase(row.frequency)} ${row.frequency != 'one_time' ? `starting` : ''} on ${deliveryTime.format('Do MMM, YYYY')} at ${deliveryTime.format('hh:mm A')}`
              :
                <div />
              }
            </div>
          )
        },
      },
      {
        Header: <strong></strong>,
        accessor: "more",
        id: "more",
        flex: 0.2,
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <div
              style={{ cursor: "pointer", width: "100%" }}
            >
              <MoreVertIcon onClick={(event) => handleMoreVertIcon(event, row)}/>
              <Popover
                open={selectedMore == row.uuid}
                anchorEl={selectedMoreAnchor}
                onClose={handleCloseCard}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}>

                <MenuList>
                  <MenuItem onClick={() => {
                    handleCloseCard()
                    setSelectedEditRow(row)
                  }}>
                    <ListItemText>Edit reminder</ListItemText>
                  </MenuItem>
                  {/*<Divider />*/}
                </MenuList>
              </Popover>
            </div>
          );
        },
      },
    ]

  const handleMoreVertIcon = (event, row) => {
    setSelectedMoreAnchor(event.currentTarget)
    setSelectedMore(row.uuid)
  }

  const handleCloseCard = () => {
    setSelectedMoreAnchor(null)
    setSelectedMore(null)
  }

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        tableStyles={tableStyles}
        paginationConfig={paginationConfig}
        handlePaginationChange={handlePaginationChange}
        getRowHeight={() => 'auto'}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
        }}
        slots={{
          toolbar: GridToolbar,
        }}
        autoHeight
      />
      <input id="textcopy" className="link-input" style={{ display: "none" }} />
      {selectedEditRow?.uuid && (
        <EditReminder
          isOpen={selectedEditRow?.uuid}
          reminder={selectedEditRow}
          onClose={() => setSelectedEditRow(null)}
        />
      )}
    </>
  );
};

export default ReminderDashboardTable;

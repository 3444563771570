import { connect } from "react-redux";
import {
  requestAdminCurrentWebhook,
  requestAdminUpdateWebhook,
} from "src/redux/actions";
import CreateEditWebhook from "./create-edit-webhook";

const mapStateToProps = (state) => ({
  currentWebhook: state.adminWebhooks?.currentWebhook,
});

export default connect(mapStateToProps, {
  requestAdminCurrentWebhook,
  requestAdminUpdateWebhook,
})(CreateEditWebhook);

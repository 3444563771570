import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import { TextInputOne, ButtonOne } from "src/components/form-inputs";
import styles from "./_read-modal.module.scss";

class ReadModal extends Component {
  state = {
    isLoading: false,
  };

  componentDidMount() {
    const { data } = this.props;

    this.props.requestAdminShowDeposit({ uuid: data?.uuid });

    this.adminShowDepositSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_SHOW_DEPOSIT,
      () => {
        this.setState({ isLoading: true });
      }
    );

    this.adminShowDepositSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_SHOW_DEPOSIT_SUCCESS,
      (data) => {
        const { oneClickCheckout } = data || {};
        let status = [];
        if (oneClickCheckout?.depositStatuses) {
          status = oneClickCheckout?.depositStatuses.map(
            (data) => data?.status
          );
          status = [...new Set(status)];
        }
        this.setState({ isLoading: false });
        this.formik.setFieldValue("id", oneClickCheckout?.uuid);
        this.formik.setFieldValue("name", oneClickCheckout?.name);
        this.formik.setFieldValue("amount", oneClickCheckout?.amount);
        this.formik.setFieldValue("folioNumber", oneClickCheckout?.folioNumber);
        this.formik.setFieldValue("amcName", oneClickCheckout?.amcName);
        this.formik.setFieldValue("status", status.toString());
      }
    );

    this.adminShowDepositSubscriptionFailure = EventBus.on(
      ACTION_TYPES.ADMIN_SHOW_DEPOSIT_FAILED,
      () => this.setState({ isLoading: false })
    );

    document.addEventListener(
      "keydown",
      (e) => this.escFunction(e, this),
      false
    );
  }

  componentWillUnmount() {
    this.adminShowDepositSubscription();
    this.adminShowDepositSubscriptionSuccess();
    this.adminShowDepositSubscriptionFailure();

    document.removeEventListener(
      "keydown",
      (e) => this.escFunction(e, this),
      false
    );
  }

  escFunction(event, props) {
    if (event.key === "Escape" && props?.props?.handleClose) {
      props?.props?.handleClose();
    }
  }

  onCopyLink = ({ link = "" }) => {
    navigator.clipboard.writeText(link);
    toast.success("Link copied to clipboard", "success");
  };

  render() {
    const { show, handleClose, showDeposit } = this.props;

    return (
      <div>
        <Dialog
          open={show}
          fullScreen={this.props.isMobile}
          fullWidth={true}
          maxWidth="md"
          onClose={handleClose}
        >
          <DialogTitle>One-Time Transaction Detail</DialogTitle>
          <Formik
            initialValues={{
              id: "",
              amount: "",
              fund: "",
              status: "",
            }}
            onSubmit={handleClose}
            innerRef={(ref) => (this.formik = ref)}
          >
            <Form>
              <div className={styles["content"]}>
                {showDeposit?.oneClickCheckout?.shortLink && (
                  <div>
                    <br />
                    <div className={styles["link"]}>
                      <a href="#" style={{wordWrap: 'break-word'}}>{showDeposit?.oneClickCheckout?.shortLink}</a>
                    </div>
                    <div
                      className={styles["copy-link"]}
                      onClick={() =>
                        this.onCopyLink({
                          link: showDeposit?.oneClickCheckout?.shortLink,
                        })
                      }
                    >
                      <img src="/img/copylink.svg" alt="copylink" />← Please
                      click to copy this transaction
                    </div>
                    <br />
                    <br />
                  </div>
                )}

                <TextInputOne
                  name="id"
                  id="id"
                  label="UUID"
                  type="text"
                  placeholder="ID"
                  sizeType="medium"
                  disabled
                />
                <TextInputOne
                  name="name"
                  id="name"
                  label="User Name"
                  type="text"
                  placeholder="User Name"
                  sizeType="medium"
                  disabled
                />
                <TextInputOne
                  name="folioNumber"
                  id="folioNumber"
                  label="Folio Number"
                  type="text"
                  placeholder="Folio Number"
                  sizeType="medium"
                  disabled
                />
                <TextInputOne
                  name="amount"
                  id="amount"
                  label="Amount"
                  type="text"
                  placeholder="Amount"
                  sizeType="medium"
                  disabled
                />
                <TextInputOne
                  name="amcName"
                  id="amcName"
                  label="Amc Name"
                  type="text"
                  placeholder="amcName"
                  sizeType="medium"
                  disabled
                />
                <TextInputOne
                  name="status"
                  id="status"
                  label="Status"
                  type="text"
                  placeholder="Status"
                  sizeType="medium"
                  disabled
                />
                <div className={styles["footer"]}>
                  <ButtonOne type="submit">OK</ButtonOne>
                </div>
              </div>
            </Form>
          </Formik>
        </Dialog>
      </div>
    );
  }
}

import { useMediaQuery, useTheme } from '@mui/material';

const withMobileDialog = (Component) => {
  const WithMobileDialog = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return <Component {...props} isMobile={isMobile} />;
  };
  return WithMobileDialog;
};
export default withMobileDialog(ReadModal);

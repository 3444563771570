import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { adminAmcList } from "src/redux/api";
import {
  requestAdminAmcsSuccess,
  requestAdminAmcsFailed,
} from "src/redux/actions";

export function* watchAdminAmcsRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_AMCS, requestAdminAmcs);
}

function* requestAdminAmcs(action) {
  try {
    const data = yield call(adminAmcList, action.payload);
    yield put(requestAdminAmcsSuccess(data?.amcs || []));
  } catch (e) {
    yield put(requestAdminAmcsFailed(e));
  }
}

import { ACTION_TYPES } from 'src/constants';

/** admin dashboard deposits */

export function requestRiskProfiles(payload) {
  return {
    type: ACTION_TYPES.REQUEST_RISK_PROFILES,
    payload,
  };
}

export function requestRiskProfilesSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_RISK_PROFILES_SUCCESS,
    payload,
  };
}

export function requestRiskProfilesFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_RISK_PROFILES_FAILED,
    payload,
  };
}

import BatchLinkCreate from "./batch-link-create";
import { connect } from "react-redux";
import { requestBatchUpload, requestSampleBatchFile,
  requestWhatsappTemplatesList } from "src/redux/actions";

const mapStateToProps = (state) => ({
  whatsappTemplates: state.whatsappTemplates,
});

export default connect(mapStateToProps, {
  requestBatchUpload,
  requestSampleBatchFile,
  requestWhatsappTemplatesList,
})(BatchLinkCreate);

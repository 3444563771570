import { connect } from 'react-redux';
import { requestAdminLogin, requestAdminVerifyOtp, requestAdminNewADLogin } from 'src/redux/actions';
import AdminLogin from './AdminLogin';

function mapStateToProps(state) {
  return {
    admin: state.admin,
  };
}

export default connect(mapStateToProps, 
  { requestAdminLogin, requestAdminVerifyOtp, requestAdminNewADLogin })(AdminLogin);

import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { adminSipList, createAdminSip, adminShowSip } from "src/redux/api";
import {
  requestAdminSipListSuccess,
  requestAdminSipListFailed,
  requestAdminCreateSipSuccess,
  requestAdminCreateSipFailed,
  requestAdminShowSipSuccess,
  requestAdminShowSipFailed,
} from "src/redux/actions";

export function* watchAdminSipRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_SIP, requestAdminSipList);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_CREATE_SIP, requestAdminCreateSip);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_SHOW_SIP, requestAdminShowSip);
}

function* requestAdminSipList(action) {
  try {
    const sip = yield call(adminSipList, action.payload);
    yield put(requestAdminSipListSuccess(sip));
  } catch (e) {
    yield put(requestAdminSipListFailed(e));
  }
}

function* requestAdminCreateSip(action) {
  try {
    const deposits = yield call(createAdminSip, action.payload);
    yield put(requestAdminCreateSipSuccess(deposits));
  } catch (e) {
    yield put(requestAdminCreateSipFailed(e));
  }
}

function* requestAdminShowSip(action) {
  try {
    const deposit = yield call(adminShowSip, action.payload);
    yield put(requestAdminShowSipSuccess(deposit));
  } catch (e) {
    yield put(requestAdminShowSipFailed(e));
  }
}

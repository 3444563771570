import { ACTION_TYPES } from 'src/constants';

export function requestLeadRecommendations(payload) {
  return {
    type: ACTION_TYPES.REQUEST_LEAD_RECOMMENDATIONS,
    payload,
  };
}

export function leadRecommendationsSuccess(payload) {
  return {
    type: ACTION_TYPES.LEAD_RECOMMENDATIONS_SUCCESS,
    payload,
  };
}

export function leadRecommendationsFailure(payload) {
  return {
    type: ACTION_TYPES.LEAD_RECOMMENDATIONS_FAILURE,
    payload,
  };
}
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function AdminAuthRoute({ component: Component, ...restOfProps }) {
  const { partnerAccessKey, userIdentityToken } = restOfProps;
  const isAuthenticated = partnerAccessKey && userIdentityToken;
  const redirectRoute = '/admin';

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Redirect to={redirectRoute} /> : <Component {...props} />
      }
    />
  );
}

export default AdminAuthRoute;

import { connect } from 'react-redux';
import CrmDashboard from './crm-dashbord';
import {
  requestBulkUploadLead,
  requestLead,
  requestShowLead,
  requestDeleteLead,
  requestUpdateLead,
  requestBenchmarks,
  requestAdminAmcs,
  requestAdminFunds
} from "src/redux/actions";

const mapStateToProps = (state) => {
  return {
    leads: state.leads,
  };
}

export default connect(mapStateToProps, { requestBulkUploadLead, requestLead, requestShowLead, requestAdminFunds,
  requestDeleteLead,requestUpdateLead, requestBenchmarks, requestAdminAmcs })(CrmDashboard);

import BatchLinks from "./batch-links";
import { connect } from "react-redux";
import { requestBatchUploadList } from "src/redux/actions";

const mapStateToProps = (state) => ({
  batchUploads: state.batchUploads
});

export default connect(mapStateToProps, {
  requestBatchUploadList,
})(BatchLinks);

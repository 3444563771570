import {
  Dialog, DialogContent, IconButton, Tabs, Tab,
  TextField, Box, Grid, Button, Typography, CircularProgress,
  Autocomplete, Divider, DialogTitle, DialogActions,
} from "@mui/material";
import { Convert } from "src/helpers";
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import { deepOrange } from '@mui/material/colors';
import React, { Component } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import dayjs from "dayjs";
import moment from 'moment';
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import TemplateField from './template-field'


class CreateMessageTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      template: "",
      templateName: "",
    }
  }

  componentDidMount() {
    this.createTemplateSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_CREATE_MESSAGE_TEMPLATE,
      () => {
        this.setState({ isLoading: true });
      }
    );

    this.createTemplateSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_CREATE_MESSAGE_TEMPLATE_SUCCESS,
      () => {
        toast.success("Template created!", "success");
        this.setState({ isLoading: false }, () => this.onClose())
      }
    );

    this.createTemplateFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_CREATE_MESSAGE_TEMPLATE_FAILED,
      () => this.setState({ isLoading: false })
    );
  }

  componentWillUnmount() {
    this.createTemplateSubscription()
    this.createTemplateSuccessSubscription()
    this.createTemplateFailureSubscription()
  }

  handleTemplateChanged = (newValue) => {
    this.setState({ template: newValue });
  }

  handleTemplateSave = () => {
    this.props.requestCreateMessageTemplate({
      message_template: {
        whatsapp_body: this.state.template,
        name: this.state.templateName
      }
    });
  };

  handleTabChange = () => {

  }

  handleNameChanged = (name) => {
    this.setState({templateName: name})
  }

  onClose = () => {
    this.setState({isLoading: false}, () => this.props.onClose && this.props.onClose())
  }

  render() {
    const { isOpen, onClose, messageTemplateVariables } = this.props;
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Dialog
          open={isOpen}
          onClose={onClose}
          fullWidth={true}
          keepMounted={false}
          maxWidth="sm"
        >
          <DialogTitle>
            <div style={{marginRight: 16}}>
              Create new message template
            </div>
          </DialogTitle>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent dividers>
            <Tabs value={"whatsapp_template"} onChange={this.handleTabChange} scrollButtons="auto">
              <Tab wrapped value={"whatsapp_template"} label={"Whatsapp/SMS message template"} />
            </Tabs>
            <Box style={{marginTop: 16}}>
              <Grid
                container
                rowSpacing={2.5}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    label="Name"
                    sizeType="medium"
                    onChange={(event) => this.handleNameChanged(event.target.value) }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TemplateField
                    onChange={this.handleTemplateChanged}
                    variables={messageTemplateVariables}/>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              style={{margin: 8}}
              disabled={this.state.isLoading}
              startIcon={this.state.isLoading ? <CircularProgress size={25} color="inherit" /> : <SaveIcon />}
              onClick={this.handleTemplateSave}
            >
              Save Template
            </Button>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
    );
  }
}
export default CreateMessageTemplate;

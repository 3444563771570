import { createTransform } from 'redux-persist';
import CryptoJS from 'crypto-js';

const EncryptionTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key, fullState) => {
    const sessionKey = fullState.adminCredentials?.token
    if(sessionKey) {
      const encryptedState = CryptoJS.AES.encrypt(JSON.stringify(inboundState), sessionKey).toString();

      return encryptedState
    } else {
      return inboundState
    }
  },
  // transform state being rehydrated
  (outboundState, key, fullState) => {
    const sessionKey = fullState.adminCredentials?.token
    if(sessionKey) {
      const decryptedState = JSON.parse(CryptoJS.AES.decrypt(outboundState, sessionKey).toString(CryptoJS.enc.Utf8));

      return decryptedState
    } else {
      return outboundState
    }
  },
  { whitelist: ['adminDeposits','adminSip','adminWithdrawals',
    'adminGenericLinks','adminAmcs','adminFunds','adminWebhooks',
    'admin','adminIsBatchUploaded','batchUploads','landingPages',
    'whatsappTemplates'] }
);

export default EncryptionTransform;
import { connect } from 'react-redux';
import ProfileDialog from './profile-dialog';
import {
  requestUpdateLead,requestRiskProfiles
} from "src/redux/actions";
const mapStateToProps = (state) =>  {
  return {
    riskProfiles: state.riskProfiles,
  }; 
}

export default connect(mapStateToProps, {requestUpdateLead,requestRiskProfiles})(ProfileDialog);
import { connect } from 'react-redux';
import CreateNotes from './create-notes';
import {
  requestCreateNotes,
  requestNoteKeywords
} from "src/redux/actions";

const mapStateToProps = (state) =>  { 
  return {
    leads: state.leads,
    noteKeywords: state.noteKeywords
  }
}

export default connect(mapStateToProps, { requestCreateNotes, requestNoteKeywords })(CreateNotes);
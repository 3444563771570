import {
  Dialog, DialogContent, IconButton,
  TextField, Box, Grid, Button, Typography, CircularProgress,
  Autocomplete, Divider, DialogTitle, DialogActions,
} from "@mui/material";
import { Convert } from "src/helpers";
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import { deepOrange } from '@mui/material/colors';
import React, { Component } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import dayjs from "dayjs";
import moment from 'moment';
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import SendIcon from '@mui/icons-material/Send';
import CreateMessageTemplate from '../create-message-template'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';

const WhatsAppDisplay = ({ messages }) => {
  return (
    <Box>
      {messages.map((message, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            marginBottom: '8px',
            '&:last-child': {
              marginBottom: 0,
            },
          }}
        >
          <Avatar alt="User Avatar" src={message.userAvatar} />
          <Box sx={{ marginLeft: '8px' }}>
            <Paper
              elevation={3}
              sx={{
                padding: '8px',
                maxWidth: '90%',
                wordWrap: 'break-word',
                borderRadius: '10px',
                backgroundColor: '#dcf8c6',
              }}
            >
              <Typography>{message.text}</Typography>
            </Paper>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

class SendReminderDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isTemplatesLoading: false,
      isGenerateLoading: false,
      template: null,
      lead: null,
      leadRecommendation: null,
      showNewTemplate: false,
      message: null,
    }
  }

  componentDidMount() {
    this.getTemplatesSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_MESSAGE_TEMPLATES,
      () => {
        this.setState({ isTemplatesLoading: true });
      }
    );

    this.getTemplatesSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_MESSAGE_TEMPLATES_SUCCESS,
      () => {
        this.setState({ isTemplatesLoading: false })
      }
    );

    this.getTemplatesdationFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_MESSAGE_TEMPLATES_FAILED,
      () => this.setState({ isTemplatesLoading: false })
    );

    this.getTemplatesSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_GENERATE_MESSAGE_FROM_TEMPLATE,
      () => {
        this.setState({ isLoading: true, isGenerateLoading: true });
      }
    );

    this.getTemplatesSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_GENERATE_MESSAGE_FROM_TEMPLATE_SUCCESS,
      (response) => {
        if(response.data?.whatsappMessage)
          this.setState({message: response.data?.whatsappMessage})
        else
          toast.success("Message has been sent to your email address for further action", "success");
        this.setState({ isLoading: false, isGenerateLoading: false})
      }
    );

    this.getTemplatesdationFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_GENERATE_MESSAGE_FROM_TEMPLATE_FAILED,
      () => this.setState({ isLoading: false, isGenerateLoading: false })
    );

    this.props.requestMessageTemplates()
  }

  componentWillUnmount() {
    this.getTemplatesSubscription()
    this.getTemplatesSuccessSubscription()
    this.getTemplatesdationFailureSubscription()

    this.getTemplatesSubscription()
    this.getTemplatesSuccessSubscription()
    this.getTemplatesdationFailureSubscription()
  }

  handleLeadChanged = (index, lead) => {
    const newReminders = this.createNewReminderAt(index)
    newReminders[index].lead = lead;
  };

  handleTemplateChanged = (newValue) => {
    this.setState({ template: newValue });
  }

  generateNewMessage = () => {
    this.props.requestGenerateMessageFromTemplate({
      lead_recommendation_uuid: this.state.leadRecommendation?.uuid,
      id: this.state.template.uuid
    })
  }

  handleSendMessage = () => {
    this.props.requestGenerateMessageFromTemplate({
      lead_recommendation_uuid: this.state.leadRecommendation?.uuid,
      id: this.state.template.uuid,
      send_reminder: true
    });
  };

  onClose = () => {
    this.setState({isLoading: false}, () => this.props.onClose && this.props.onClose())
  }

  handleCloseNewTemplate = () => {
    this.setState({showNewTemplate: false})
  }

  handleSendWhatsapp = () => {
    let number = this.state.lead.phoneNumber?.replace(/\D/g, '');
    if(!number) {
      window.open(`https://wa.me/?text=${encodeURIComponent(this.state.message)}`, '_blank');
      return
    }
    // Check if the number starts with '0', remove it
    if (number?.startsWith('0')) {
        number = number.substring(1);
    }

    // Check if the number length is 10 or more
    if (number?.length < 10) {
        return "Invalid number";
    }

    // If number length is more than 10, trim it to 10 digits
    if (number?.length > 10) {
        number = number.substring(number.length - 10);
    }

    // Add the Indian prefix
    number = '+91' + number;
    window.open(`https://wa.me/${number}?text=${encodeURIComponent(this.state.message)}`, '_blank');
  }

  render() {
    const { isOpen, onClose, messageTemplates, leads } = this.props;
    const { lead, isTemplatesLoading } = this.state
    const recommendations = lead?.leadRecommendations || []

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Dialog
          open={isOpen}
          onClose={onClose}
          fullWidth={true}
          keepMounted={false}
          maxWidth="sm"
        >
          <DialogTitle>
            <div style={{marginRight: 16}}>
              Send reminder
            </div>
          </DialogTitle>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent dividers>
            <Box style={{marginTop: 16}}>
              <Grid
                container
                rowSpacing={2.5}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={6}>
                  <Autocomplete
                    fullWidth
                    options={leads}
                    getOptionLabel={(option) => option.fullName}
                    onChange={(event, newValue) => this.setState({lead: newValue })}
                    renderInput={(params) => (
                      <TextField {...params} label="Lead" />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    fullWidth
                    options={recommendations}
                    disabled={!lead}
                    getOptionLabel={(option) => option.fund?.name}
                    onChange={(event, newValue) => this.setState({leadRecommendation: newValue })}
                    renderInput={(params) => (
                      <TextField {...params} label="Recommendation" />
                    )}
                  />
                </Grid>

                <Grid item xs={9}>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField {...params} label="Message Template" />
                    )}
                    disabled={!lead || isTemplatesLoading}
                    fullWidth
                    options={messageTemplates}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => this.setState({template: newValue})}
                  />
                </Grid>

                <Grid item xs={3} style={{display: 'flex'}}>
                  <Button
                    size="small"
                    variant="outlined"
                    style={{fontSize: 9, alignSelf: 'center'}}
                    startIcon={<MapsUgcIcon />}
                    onClick={() => this.setState({showNewTemplate: true})}>
                    New template
                  </Button>
                </Grid>

                <Grid item xs={5}>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={this.generateNewMessage}
                    startIcon={this.state.isGenerateLoading ? <CircularProgress size={20} color="inherit" /> : <div />}>
                    Generate message
                  </Button>
                </Grid>
                {
                  this.state.message &&
                    <Grid item xs={12}>
                      <WhatsAppDisplay messages={[{text: this.state.message}]} />
                    </Grid>
                }
              </Grid>
            </Box>
          </DialogContent>

          { this.state.message &&
            <DialogActions>
              <Button
                variant="contained"
                disabled={this.state.isLoading}
                startIcon={<WhatsAppIcon />}
                style={{margin: 8, borderColor: '#25d366', color: '#fff', backgroundColor: '#25d366', fontSize: 12}}
                onClick={this.handleSendWhatsapp}
              >
                Send message on WhatsApp
              </Button>
              <Button
                variant="contained"
                style={{margin: 8, fontSize: 12}}
                disabled={this.state.isLoading}
                startIcon={this.state.isLoading ? <CircularProgress size={25} color="inherit" /> : <SendIcon />}
                onClick={this.handleSendMessage}
              >
                Send message to own email
              </Button>
            </DialogActions>
          }

          {
            this.state.showNewTemplate &&
              <CreateMessageTemplate
                isOpen={this.state.showNewTemplate}
                onClose={this.handleCloseNewTemplate} />
          }
        </Dialog>
      </LocalizationProvider>
    );
  }
}
export default SendReminderDialog;

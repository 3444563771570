import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import AdminLayout from "./layout";
import {
  OneTimeTransactions,
  SipMandate,
  Developers,
  ApiKeys,
  Webhooks,
  BatchLinkCreate,
  BatchLinks,
  GenericLinks,
  LandingPagesCreate,
  LandingPages,
  CrmDashboard,
  ReminderDashboard,
} from "./screens";

class V2Admin extends Component {
  render() {
    const { partner } = this.props
    return (
      <>
        <AdminLayout partner={partner}>
          <Switch>
            {
              partner.crmOnly ?
                <Route exact path="/admin" component={CrmDashboard} />
              :
                <Route exact path="/admin" component={OneTimeTransactions} />
            }
            <Route exact path="/admin/sip" component={SipMandate} />
            <Route exact path="/admin/generic-links" component={GenericLinks} />
            <Route exact path="/admin/developers" component={Developers} />
            <Route
              exact
              path="/admin/developers/api-keys"
              component={ApiKeys}
            />
            <Route
              exact
              path="/admin/developers/webhooks"
              component={Webhooks}
            />
            <Route
              exact
              path="/admin/batch-link-create"
              component={BatchLinkCreate}
            />
            <Route
              exact
              path="/admin/batch-links"
              component={BatchLinks}
            />
            <Route
              exact
              path="/admin/landing-pages-create/:id"
              component={LandingPagesCreate}
            />
            <Route
              exact
              path="/admin/landing-pages"
              component={LandingPages}
            />
            <Route
              exact
              path="/admin/crm/dashboard"
              component={CrmDashboard}
            />
            <Route
              exact
              path="/admin/crm/reminders"
              component={ReminderDashboard}
            />
          </Switch>
        </AdminLayout>
      </>
    );
  }
}

export default withRouter(V2Admin);

import React, { useMemo, useState } from "react";
import moment from "moment";
import { Convert } from "src/helpers";
import { DataTable, TextOne, LinkOne } from "../../../../../components";
import styles from "./_one-time-transactions-table.module.scss";
import ReadModal from "../read-modal";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash'

const API_ENDPOINT_URL = process.env.API_ENDPOINT_URL || "https://surface.thesavvyapp.in";

const OneTimeTransactionsTable = (props) => {
  const [selectedRowData, setSelectedRowData] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const tableStyles = {
    bodyRow: {
      backgroundColor: "#ffffff",
      borderBottomColor: "#f3f3f3",
    },
    tableHeader: {
      backgroundColor: "#ffffff",
      borderTop: "1px solid #F3F3F3",
      borderBottom: "1px solid #F3F3F3",
    },
  };

  const { totalItems, data, handlePaginationChange } = props;

  const pageSize = 50;

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };

  const columns = _.compact([
    {
      Header: <strong>User Name</strong>,
      accessor: "name",
      id: "name",
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return (
          <div
            onClick={() => setSelectedRowData(row)}
            style={{ cursor: "pointer", width: "100%" }}
          >
            {value}
          </div>
        );
      },
    },
    {
      Header: <strong>Amount</strong>,
      accessor: "amount",
      disableSortBy: true,
      Cell: ({ row, value }) => (
        <div
          onClick={() => setSelectedRowData(row)}
          style={{ cursor: "pointer", width: "100%" }}
        >
          {Convert.toCurrencyValue(value || 0)}
        </div>
      ),
    },
    {
      Header: <strong>Status</strong>,
      accessor: "depositStatuses",
      disableSortBy: true,
      Cell: ({ row, value }) => {
        let status = {};
        if (value && value.length > 0) {
          status = value[0];
        }
        return (
          <div
            onClick={() => setSelectedRowData(row)}
            style={{ cursor: "pointer", width: "100%" }}
          >
            {status?.status}
          </div>
        );
      },
    },
    isMobile ? null : {
      Header: <strong>Expiry date</strong>,
      accessor: "expiry",
      id: "expiry",
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return (
          <div
            onClick={() => setSelectedRowData(row)}
            style={{ cursor: "pointer", width: "100%" }}
          >
            {value}
          </div>
        );
      },
    },
    isMobile ? null : {
      Header: <strong>UUID</strong>,
      accessor: "uuid",
      id: "uuid",
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return (
          <div
            onClick={() => setSelectedRowData(row)}
            style={{ cursor: "pointer", width: "100%" }}
          >
            {value.slice(0,25)}...
          </div>
        );
      },
    },
  ])

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        tableStyles={tableStyles}
        paginationConfig={paginationConfig}
        handlePaginationChange={handlePaginationChange}
      />
      <input id="textcopy" className="link-input" style={{ display: "none" }} />
      {selectedRowData?.uuid && (
        <ReadModal
          show={selectedRowData?.uuid}
          data={selectedRowData}
          handleClose={() => setSelectedRowData(null)}
        />
      )}
    </>
  );
};

export default OneTimeTransactionsTable;

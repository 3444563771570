import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { adminGenericLinksList, createAdminGenericLink,
  updateAdminGenericLink, adminShowGenericLink } from "src/redux/api";
import {
  requestAdminGenericLinksListSuccess,
  requestAdminGenericLinksListFailed,
  requestAdminCreateGenericLinkSuccess,
  requestAdminCreateGenericLinkFailed,
  requestAdminShowGenericLinkSuccess,
  requestAdminShowGenericLinkFailed,
  requestAdminUpdateGenericLinkSuccess,
  requestAdminUpdateGenericLinkFailed,
} from "src/redux/actions";

export function* watchAdminGenericLinksRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_GENERIC_LINKS, requestAdminGenericLinksList);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_CREATE_GENERIC_LINK, requestAdminCreateGenericLink);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_UPDATE_GENERIC_LINK, requestAdminUpdateGenericLink);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_SHOW_GENERIC_LINK, requestAdminShowGenericLink);
}

function* requestAdminGenericLinksList(action) {
  try {
    const genericLinks = yield call(adminGenericLinksList, action.payload);
    yield put(requestAdminGenericLinksListSuccess(genericLinks));
  } catch (e) {
    yield put(requestAdminGenericLinksListFailed(e));
  }
}

function* requestAdminCreateGenericLink(action) {
  try {
    const genericLink = yield call(createAdminGenericLink, action.payload);
    yield put(requestAdminCreateGenericLinkSuccess(genericLink));
  } catch (e) {
    yield put(requestAdminCreateGenericLinkFailed(e));
  }
}

function* requestAdminUpdateGenericLink(action) {
  try {
    const genericLink = yield call(updateAdminGenericLink, action.payload);
    yield put(requestAdminUpdateGenericLinkSuccess(genericLink));
  } catch (e) {
    yield put(requestAdminUpdateGenericLinkFailed(e));
  }
}

function* requestAdminShowGenericLink(action) {
  try {
    const genericLink = yield call(adminShowGenericLink, action.payload);
    yield put(requestAdminShowGenericLinkSuccess(genericLink));
  } catch (e) {
    yield put(requestAdminShowGenericLinkFailed(e));
  }
}

import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { convertToSaleLeadApi } from "src/redux/api";
import {
    convertToSaleLeadSuccess,
    convertToSaleLeadFailure,
} from "src/redux/actions";

export function* watchConvertToSaleLead() {
  yield takeEvery(ACTION_TYPES.REQUEST_CONVERT_TO_SALE_LEAD, requestConvertToSaleLead);
}

function* requestConvertToSaleLead(action) {
  try {
    const ConvertToSaleLeadData = yield call(convertToSaleLeadApi, action.payload);
    yield put(convertToSaleLeadSuccess(ConvertToSaleLeadData));
  } catch (e) {
    yield put(convertToSaleLeadFailure(e));
  }
}
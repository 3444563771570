import { connect } from 'react-redux';
import SendReminderDialog from './send-reminder-dialog';
import {
  requestGenerateMessageFromTemplate,
  requestMessageTemplates
} from "src/redux/actions";

const mapStateToProps = (state) =>  { 
  return {
    messageTemplates: state.messageTemplates,
    leads: state.leads,
  }
}

export default connect(mapStateToProps, { 
  requestGenerateMessageFromTemplate,
  requestMessageTemplates
})(SendReminderDialog);
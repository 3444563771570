import { ACTION_TYPES } from "src/constants";
import { INITIAL_STATE } from "../store";

export default function AdminReducer(state = INITIAL_STATE.admin, action) {
  switch (action.type) {
    case ACTION_TYPES.ADMIN_SANDBOX_TOGGLE:
      return {
        sandboxToggle: action.payload,
      };
    case ACTION_TYPES.TOGGLE_MOBILE_SIDE_MENU:
      return {
        ...state,
        showMobileSideMenu: !state.showMobileSideMenu
      }

    case ACTION_TYPES.REQUEST_ADMIN_LOGIN:
    case ACTION_TYPES.REQUEST_ADMIN_LOGOUT:
    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.admin

    default:
      return state;
  }
}

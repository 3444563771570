import { ACTION_TYPES } from "src/constants";
import { INITIAL_STATE } from "../store";
import _ from 'lodash';

export default function LeadsReducer(state = INITIAL_STATE.leads, action) {
  switch (action.type) {
    case ACTION_TYPES.LEAD_SUCCESS: {
      return action.payload
    }

    case ACTION_TYPES.CREATE_LEADS_SUCCESS: {
      return [...state, ...action.payload]
    }

    case ACTION_TYPES.DELETE_LEAD_SUCCESS: {
      const uuid = action.payload.uuid
      return _.filter(state, (lead) => lead.uuid != uuid)
    }
    case ACTION_TYPES.BULK_UPLOAD_LEAD_SUCCESS: {
      return [...state, ...action.payload.leads]
    }

    case ACTION_TYPES.UPDATE_LEAD_SUCCESS:
    case ACTION_TYPES.CONVERT_TO_SALE_LEAD_SUCCESS: {
      const uuid = action.payload.uuid
      return [..._.filter(state, (lead) => lead.uuid != uuid), action.payload]
    }

    case ACTION_TYPES.CREATE_LEAD_RECOMMENDATIONS_SUCCESS: {
      const uuid = action.payload.leadUuid
      const lead = { ..._.find(state, (stateLead) => stateLead.uuid == uuid) }
      lead.leadRecommendations = [...lead.leadRecommendations, ...action.payload.leadRecommendations]
      return [..._.filter(state, (lead) => lead.uuid != uuid), lead]
    }

    case ACTION_TYPES.REQUEST_CREATE_LEAD_REMINDER_SUCCESS: {
      const updatedLeadUuids = _.map(action.payload.leads, 'uuid')

      return [..._.filter(state, (lead) => !_.includes(updatedLeadUuids, lead.uuid)), ...action.payload.leads]
    }

    case ACTION_TYPES.REQUEST_UPDATE_LEAD_REMINDER_SUCCESS: {
      const newLead = action.payload.lead
      return [..._.filter(state, (lead) => lead.uuid != newLead.uuid), newLead]
    }

    case ACTION_TYPES.REQUEST_CREATE_NOTES_SUCCESS: {
      const updatedLeadUuids = _.map(action.payload.leads, 'uuid')

      return [..._.filter(state, (lead) => !_.includes(updatedLeadUuids, lead.uuid)), ...action.payload.leads]
    }

    case ACTION_TYPES.REQUEST_ADMIN_LOGOUT:
    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.leads;

    default:
      return state;
  }
}
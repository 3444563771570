export const INITIAL_STATE = {
  credentials: {
    partnerAccessKey: "",
    userIdentityToken: "",
  },
  user: {
    uuid: "",
  },
  admin: {
    sandboxToggle: true,
    showMobileSideMenu: false,
  },
  adminCredentials: { token: "" },
  adminTransactions: {},
  adminDeposits: {
    depositsList: {},
    createdDesposit: {},
    showDeposit: {},
  },
  adminSip: {
    sipList: {},
    createdSip: {},
    showSip: {},
  },
  adminWithdrawals: {
    withdrawalsList: {},
    createdWithdrawal: {},
    showWithdrawal: {},
  },
  adminGenericLinks: {
    genericLinksList: [],
    createdGenericLink: {},
    showGenericLink: {},
  },
  adminAmcs: [],
  adminFunds: [],
  adminWebhook: {
    webhooks: [],
    currentWebhook: {},
  },
  batchUploads: [],
  adminIsBatchUploaded: false,
  landingPages: [],
  whatsappTemplates: [],
  bulkUploadLead : [],
  leads : [],
  leadRecommendations: [],
  benchmarks: [],
  leadNotes: [],
  noteKeywords: [],
  riskProfiles: [],
  messageTemplates: [],
  messageTemplateVariables: [],
};

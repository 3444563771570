import { connect } from "react-redux";
import {
  requestAdminSipList,
  requestAdminCreateSipClear,
  resetFolioList,
} from "src/redux/actions";
import SipMandate from "./sip-mandate";

const mapStateToProps = (state) => ({
  sipMandate: state.adminSip?.sipList,
});

export default connect(mapStateToProps, {
  requestAdminSipList,
  requestAdminCreateSipClear,
  resetFolioList,
})(SipMandate);

import { connect } from "react-redux";
import {
  requestAdminAmcs,
  requestAdminFunds,
  requestAdminFundsSuccess,
  requestAdminCreateGenericLink,
  requestLandingPages
} from "src/redux/actions";
import CreateGenericLink from "./create-generic-link";

const mapStateToProps = (state) => ({
  adminAmcs: state.adminAmcs,
  adminFunds: state.adminFunds,
  landingPages: state.landingPages
});

export default connect(mapStateToProps, {
  requestAdminAmcs,
  requestAdminFunds,
  requestAdminFundsSuccess,
  requestAdminCreateGenericLink,
  requestLandingPages
})(CreateGenericLink);

import { connect } from 'react-redux';
import ReminderDashboard from './reminder-dashboard';
import {
} from "src/redux/actions";
import _ from 'lodash';

const mapStateToProps = (state) =>  {
  const { leads } = state
  const leadRecommendations = _.flatMap(leads, (lead) => _.map(lead.leadRecommendations, (rec) => ({
    ...rec,
    lead: lead
  })))

  const leadReminders = _.flatMap(leadRecommendations, (rec) => _.map(rec.leadReminders, (reminder) => ({
    ...reminder,
    lead: rec.lead,
    leadRecommendation: rec,
  })))

  return {
    reminders: _.reverse(_.sortBy(leadReminders, (rem) => rem.lead?.createdAt))
  };
}

export default connect(mapStateToProps, { })(ReminderDashboard);

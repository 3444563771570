import React, { Component } from "react";
import { Form, Formik } from "formik";
import EventBus from "eventing-bus";
import { toast } from "react-toastify";
import { ACTION_TYPES } from "src/constants";
import { Loader } from "src/components";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextInputOne, ButtonOne } from "src/components/form-inputs";
import styles from "./_create-edit-webhook.module.scss";

class CreateEditWebhook extends Component {
  state = {
    isModalOpen: false,
    isLoading: false,
    isUpdating: false,
    eventsHook: [],
  };

  componentDidMount() {
    document.addEventListener(
      "keydown",
      (e) => this.escFunction(e, this),
      false
    );

    this.webhookSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_CURRENT_WEBHOOKS,
      () => {
        this.setState({ isLoading: true });
      }
    );

    this.webhookSuccessSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_CURRENT_WEBHOOKS_SUCCESS,
      (response) => {
        this.setState({
          isLoading: false,
          eventsHook: response?.configuredHookTypes,
        });
        this.formik.setFieldValue("url", response?.url);
      }
    );

    this.webhookFailureSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_CURRENT_WEBHOOKS_FAILED,
      () => this.setState({ isLoading: false })
    );

    this.updateWebhookSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_UPDATE_WEBHOOKS,
      () => {
        this.setState({ isUpdating: true });
      }
    );

    this.updateWebhookSuccessSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_UPDATE_WEBHOOKS_SUCCESS,
      () => {
        this.setState({ isUpdating: false });
        toast.success("Endpoint saved successfully", "success");
      }
    );

    this.updateWebhookFailureSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_UPDATE_WEBHOOKS_FAILED,
      () => this.setState({ isUpdating: false })
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "keydown",
      (e) => this.escFunction(e, this),
      false
    );

    this.webhookSubscription();
    this.webhookSuccessSubscription();
    this.webhookFailureSubscription();

    this.updateWebhookSubscription();
    this.updateWebhookSuccessSubscription();
    this.updateWebhookFailureSubscription();
  }

  escFunction(event, props) {
    if (event.key === "Escape" && props?.props?.handleClose) {
      props?.props?.handleClose();
    }
  }

  componentDidUpdate() {
    const { isModalOpen } = this.state;

    if (this.props.show && !isModalOpen) {
      this.setState(
        { isModalOpen: true },
        this.props.requestAdminCurrentWebhook
      );
    }
    if (!this.props.show && isModalOpen) {
      this.setState({ isModalOpen: false });
    }
  }

  handleOnSubmit = (formValues) => {
    const { url } = formValues || {};
    const { eventsHook } = this.state;
    this.props.requestAdminUpdateWebhook({
      data: {
        webhook: {
          url,
          configured_hook_types: eventsHook,
        },
      },
    });
  };

  addEvent = () => {
    if (this.formik?.values?.event) {
      const { eventsHook } = this.state;
      this.setState({
        eventsHook: [...eventsHook, this.formik?.values?.event],
      });
      this.formik.setFieldValue("event", "");
    }
  };

  deleteEvent = (index) => {
    const { eventsHook } = this.state;
    const filteredEventsHook = eventsHook.filter((_, ind) => ind !== index);
    this.setState({ eventsHook: filteredEventsHook });
  };

  render() {
    const { show, handleClose } = this.props;
    const { isUpdating, eventsHook, isLoading } = this.state;
    return (
      <div>
        <Dialog
          open={show}
          fullWidth={true}
          maxWidth="md"
          onClose={handleClose}
        >
          <DialogTitle>Add a Webhook Endpoint</DialogTitle>
          <Formik
            initialValues={{ url: "", description: "" }}
            innerRef={(ref) => (this.formik = ref)}
            onSubmit={this.handleOnSubmit}
          >
            <Form>
              <div className={styles["content"]}>
                <TextInputOne
                  name="url"
                  id="url"
                  label="Endpoint URL"
                  type="text"
                  placeholder="http://"
                  sizeType="medium"
                />
                {/* <TextInputOne
                  name="description"
                  id="description"
                  row="3"
                  label="Description"
                  type="textarea"
                  placeholder="Add an optional description of what this webhook endpoint is used for"
                  sizeType="medium"
                /> */}
                <div className={styles["events"]}>
                  <TextInputOne
                    name="event"
                    id="event"
                    label="Events to send"
                    type="textarea"
                    placeholder="Event"
                    sizeType="medium"
                    containerstyles={{ width: "100%" }}
                  />
                  <ButtonOne
                    className={styles["event-add-btn"]}
                    onClick={this.addEvent}
                  >
                    <div>Add</div>
                  </ButtonOne>
                </div>
                {eventsHook &&
                  eventsHook.length > 0 &&
                  eventsHook.map((data, index) => {
                    return (
                      <div key={index} className={styles["event-list"]}>
                        <div>{data}</div>
                        <ButtonOne
                          btnSize="h-small"
                          onClick={() => this.deleteEvent(index)}
                        >
                          X
                        </ButtonOne>
                      </div>
                    );
                  })}
                {!eventsHook ||
                  (eventsHook && eventsHook.length === 0 && (
                    <div className={styles["empty-events-box"]}>
                      No events selected
                      <br />
                      Search for events with the dropdown above
                    </div>
                  ))}
                <div className={styles["footer"]}>
                  <div className={styles["cancel-btn"]}>
                    <ButtonOne onClick={handleClose} disabled={isUpdating}>
                      Cancel
                    </ButtonOne>
                  </div>
                  <ButtonOne
                    btnType="success"
                    type="submit"
                    disabled={isUpdating}
                    className={styles["add-btn"]}
                  >
                    <img
                      src="/img/plusnew.svg"
                      alt="plus"
                      className={styles["plus-btn"]}
                    />
                    <div>{isUpdating ? <>Loading... </> : "Add endpoint"}</div>
                  </ButtonOne>
                </div>
              </div>
            </Form>
          </Formik>
        </Dialog>
        <Loader loading={isLoading} />
      </div>
    );
  }
}

export default CreateEditWebhook;

import { ApiRequest } from "src/helpers";
import { API_END_POINTS } from "src/constants";

export function createLeadReminder(data) {
  return ApiRequest.fetchAdmin({
    method: "post",
    url: API_END_POINTS.CREATE_LEAD_REMINDERS,
    data,
  }).then((response) => response.data);
}

export function updateLeadReminder(data) {
  return ApiRequest.fetchAdmin({
    method: "put",
    url: `${API_END_POINTS.CREATE_LEAD_REMINDERS}/${data.id}`,
    data,
  }).then((response) => response.data);
}
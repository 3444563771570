import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { adminLandingPagesList, createAdminLandingPage,
  updateAdminLandingPage } from "src/redux/api";
import {
  requestLandingPagesSuccess,
  requestLandingPagesFailed,
  requestCreateLandingPageSuccess,
  requestCreateLandingPageFailed,
  requestUpdateLandingPageSuccess,
  requestUpdateLandingPageFailed
} from "src/redux/actions";

export function* watchLandingPagesRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_LANDING_PAGES, requestLandingPages);
  yield takeEvery(ACTION_TYPES.REQUEST_CREATE_LANDING_PAGE, requestCreateLandingPage);
  yield takeEvery(ACTION_TYPES.REQUEST_UPDATE_LANDING_PAGE, requestUpdateLandingPage);
}

function* requestLandingPages(action) {
  try {
    const data = yield call(adminLandingPagesList, action.payload);
    yield put(requestLandingPagesSuccess(data));
  } catch (e) {
    yield put(requestLandingPagesFailed(e));
  }
}

function* requestCreateLandingPage(action) {
  try {
    const data = yield call(createAdminLandingPage, action.payload);
    yield put(requestCreateLandingPageSuccess(data));
  } catch (e) {
    yield put(requestCreateLandingPageFailed(e));
  }
}

function* requestUpdateLandingPage(action) {
  try {
    const data = yield call(updateAdminLandingPage, action.payload);
    yield put(requestUpdateLandingPageSuccess(data));
  } catch (e) {
    yield put(requestUpdateLandingPageFailed(e));
  }
}

import React, { Component } from "react";
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import { Loader, Header } from "src/components";
import { Form, Formik } from "formik";
import moment from "moment";
import {
  TextInputOne,
  ButtonOne,
  InputSwitch,
} from "src/components/form-inputs";
import { withRouter } from "react-router-dom";
import ApiKeysTable from "./api-keys-table";
import { Container } from "../../../../components";
import styles from "./_api-keys.module.scss";
import { Helpers } from "../../../../helpers";

class ApiKeys extends Component {
  state = {
    loading: false,
    pageNumber: 1,
    filteredData: [],
    totalCount: 0,
  };


  render() {
    const { loading, filteredData, totalCount } = this.state;

    return (
      <div className={styles["api-keys"]}>
        <Header title="API Keys" />
        <Container>
          <div className={styles["api-key-box"]}>
            <div className={styles["api-key-title"]}>API Keys</div>
            <div>
              <Formik
                initialValues={{
                  is_sandbox_mode: false,
                }}
              >
                <Form>
                  <InputSwitch
                    // val={`${values.is_api_key ? 'Yes' : 'No'}`}
                    name="is_sandbox_mode"
                    id="is_sandbox_mode"
                    labelPosition="right"
                    onClick={Helpers.switchMode}
                    checked={this.props.sandboxToggle}
                  />
                </Form>
              </Formik>
            </div>
            <div className={styles["api-key-desc"]}>
              You are viewing {this.props.sandboxToggle ? "sandbox" : "live"}{" "}
              keys. Toggle to view{" "}
              {this.props.sandboxToggle ? "live" : "sandbox"} keys
            </div>
          </div>
          <div className={styles["table-data"]}>
            <ApiKeysTable
              data={filteredData}
              totalItems={totalCount}
            />
          </div>
        </Container>
        <Loader loading={loading}/>
      </div>
    );
  }
}

export default withRouter(ApiKeys);

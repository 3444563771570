export const paymentMethods = [
  {
    name: "UPI",
    value: "upi",
  },
  {
    name: "Net Banking",
    value: "internet_banking",
  },
  {
    name: "Bank Transfer",
    value: "bank_transfer",
  },
  {
    name: "Existing Mandate",
    value: "mandate"
  }
];

import { ACTION_TYPES } from "src/constants";
import { INITIAL_STATE } from "../store";
import _ from 'lodash';

export default function MessageTemplatesReducer(state = INITIAL_STATE.messageTemplates, action) {
  switch (action.type) {

    case ACTION_TYPES.REQUEST_MESSAGE_TEMPLATES_SUCCESS: {
      return action.payload.messageTemplates
    }

    case ACTION_TYPES.REQUEST_CREATE_MESSAGE_TEMPLATE_SUCCESS: {
      return [action.payload.messageTemplate, ...state]
    }

    case ACTION_TYPES.REQUEST_UPDATE_MESSAGE_TEMPLATE_SUCCESS: {
      return [action.payload.messageTemplate, ..._.filter(state, (template) => template.uuid != action.payload.messageTemplate.uuid)]
    }

    case ACTION_TYPES.REQUEST_ADMIN_LOGOUT:
    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.messageTemplates;

    default:
      return state;
  }
}
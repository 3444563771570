import React, { Component } from "react";
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import { withRouter } from "react-router-dom";
import { Container } from "../../../../components";
import { Header, Loader } from "src/components";
import styles from "./_landing-pages.module.scss";
import { useEffect } from "react";
import GjsEditor from "@grapesjs/react";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import _ from "lodash";

class LandingPagesCreate extends Component {
  state = {
    isLoading: false,
    editor: null,
    selectedLandingPage: null,
    componentData: null,
    isValidForm: false,
    landingPageName: "",
  };

  componentDidMount() {
    this.requestLandingPages = EventBus.on(
      ACTION_TYPES.REQUEST_CREATE_LANDING_PAGES,
      () => this.setState({ isLoading: true })
    );
    this.requestLandingPagesSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_CREATE_LANDING_PAGE_SUCCESS,
      this.handleLandingPageCreateSuccess
    );
    this.requestLandingPagesFailure = EventBus.on(
      ACTION_TYPES.REQUEST_CREATE_LANDING_PAGE_FAILURE,
      () => this.setState({ isLoading: false })
    );

    this.requestUpdateLandingPages = EventBus.on(
      ACTION_TYPES.REQUEST_UPDATE_LANDING_PAGES,
      () => this.setState({ isLoading: true })
    );
    this.requestUpdateLandingPagesSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_UPDATE_LANDING_PAGE_SUCCESS,
      this.handleLandingPageUpdateSuccess
    );
    this.requestUpdateLandingPagesFailure = EventBus.on(
      ACTION_TYPES.REQUEST_UPDATE_LANDING_PAGE_FAILURE,
      () => this.setState({ isLoading: false })
    );

    const id = this.props.match?.params?.id;

    if (id != null && id != "") {
      const landingPage = _.find(
        this.props.landingPages,
        (lp) => lp.uuid == id
      );
      this.setState({
        selectedLandingPage: landingPage,
        landingPageName: landingPage?.landingPageName,
        isValidForm: landingPage?.landingPageName,
      });
    }
  }

  componentWillUnmount() {
    this.requestLandingPages();
    this.requestLandingPagesSuccess();
    this.requestLandingPagesFailure();

    this.requestUpdateLandingPages();
    this.requestUpdateLandingPagesSuccess();
    this.requestUpdateLandingPagesFailure();
  }

  onEditor = (editor) => {
    this.setState({ editor: editor });
    const selectedPage = this.state.selectedLandingPage;
    const amConfig = editor.AssetManager.getConfig();

    if(this.state.selectedLandingPage) {
      editor.setComponents(JSON.parse(this.state.selectedLandingPage.components))
      editor.setStyle(this.state.selectedLandingPage.cssBody)
    }
  };

  _onChangePageName = (e) => {
    this.setState({ landingPageName: e.target.value }, () => {
      if (
        this.state.landingPageName != null &&
        this.state.landingPageName != ""
      ) {
        this.setState({ isValidForm: true });
      } else {
        this.setState({ isValidForm: false });
      }
    });
  };

  onSubmit = () => {
    this.state.editor.runCommand("get-tailwindCss", {
      callback: (twcss) => {
        const finalCss = this.state.editor.getCss() + " " + twcss;

        if (this.state.selectedLandingPage) {
          this.props.requestUpdateLandingPage({
            id: this.state.selectedLandingPage.uuid,
            landing_page_name: this.state.landingPageName,
            html_body: this.state.editor.getHtml(),
            css_body: finalCss,
            components: JSON.stringify(this.state.editor.getComponents()),
          });
        } else {
          this.props.requestCreateLandingPage({
            landing_page_name: this.state.landingPageName,
            html_body: this.state.editor.getHtml(),
            css_body: finalCss,
            components: JSON.stringify(this.state.editor.getComponents()),
          });
        }
      },
    });
  };

  handleLandingPageCreateSuccess = () => {
    this.props.history.push("/admin/landing-pages");
  };

  handleLandingPageUpdateSuccess = () => {
    toast.success("Updated successfully!", "success");
  };

  render() {
    const { isLoading, showCreateModal, showGeneratedLinkModal, search } =
      this.state;
    const { genericLinks } = this.props;

    return (
      <div className={styles["one-time-transactions"]}>
        <Header title="Create new landing page" />
        <Grid container spacing={3} style={{ marginBottom: "8px" }}>
          <Grid item xs={4} alignItems="center">
            <TextField
              error={
                this.state.landingPageName == null ||
                this.state.landingPageName == ""
              }
              style={{ width: "100%" }}
              onChange={this._onChangePageName}
              label="Enter landing page name"
              id="landingPageName"
              value={this.state.landingPageName}
            />
          </Grid>
          <Grid item xs={2} alignItems="center">
            <Button
              onClick={this.onSubmit}
              variant="contained"
              style={{ width: "100%" }}
              startIcon={<SaveIcon />}
              disabled={!this.state.isValidForm}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <Container>
          <GjsEditor
            grapesjs="https://unpkg.com/grapesjs"
            grapesjsCss="https://unpkg.com/grapesjs/dist/css/grapes.min.css"
            options={{
              height: "100vh",
              storageManager: false,
              assetManager: {
                assets: [
                  {
                    src: "https://cdn.savvyapp.in/assets/SBI_logo.png",
                    height: 350,
                    width: 250,
                    name: "SBI logo",
                  },
                ],
              },
            }}
            onEditor={this.onEditor}
            plugins={[
              {
                id: 'grapesjs-preset-webpage',
                src: "https://unpkg.com/grapesjs-preset-webpage@1.0.2",
              },
              {
                id: 'grapesjs-tabs',
                src: 'https://unpkg.com/grapesjs-tabs@1.0.6',
              },
              {
                id: 'grapesjs-custom-code',
                src: 'https://unpkg.com/grapesjs-custom-code@1.0.1',
              },
              {
                src: 'grapesjs-typed',
                src: 'https://unpkg.com/grapesjs-typed@1.0.5',
              },
              {
                id: "gjs-blocks-basic",
                src: "https://unpkg.com/grapesjs-blocks-basic",
              },
              {
                id: "grapesjs-tailwind",
                src: "https://unpkg.com/grapesjs-tailwind",
              },
            ]}
          />
        </Container>
        <Loader loading={isLoading} />
      </div>
    );
  }
}

export default withRouter(LandingPagesCreate);

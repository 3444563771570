import { ACTION_TYPES } from 'src/constants';

/** admin dashboard deposits */

export function requestNoteKeywords(payload) {
  return {
    type: ACTION_TYPES.REQUEST_NOTE_KEYWORDS,
    payload,
  };
}

export function requestNoteKeywordsSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_NOTE_KEYWORDS_SUCCESS,
    payload,
  };
}

export function requestNoteKeywordsFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_NOTE_KEYWORDS_FAILED,
    payload,
  };
}

export function requestCreateNotes(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_NOTES,
    payload,
  };
}

export function requestCreateNotesSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_NOTES_SUCCESS,
    payload,
  };
}

export function requestCreateNotesFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_NOTES_FAILED,
    payload,
  };
}
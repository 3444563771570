import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { bulkUploadLeadApi } from "src/redux/api";
import {
    bulkUploadLeadSuccess,
    bulkUploadLeadFailure,
} from "src/redux/actions";

export function* watchBulkUploadLeadApi() {
  yield takeEvery(ACTION_TYPES.REQUEST_BULK_UPLOAD_LEAD, requestBulkUploadLead);
}

function* requestBulkUploadLead(action) {
  try {
    const bulkUploadData = yield call(bulkUploadLeadApi, action.payload);
    yield put(bulkUploadLeadSuccess(bulkUploadData));
  } catch (e) {
    console.error("Error fetching Bulk Upload Lead API:", e);
    yield put(bulkUploadLeadFailure(e));
  }
}
import React, { Component } from "react";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from "@mui/material";
import { Convert } from "src/helpers/convert";
import {
  TextInputOne,
  InputSelectOne,
} from "src/components/form-inputs";
import styles from "./_generated-link.module.scss";

class GeneratedLink extends Component {
  onCopyLink = ({ link = "" }) => {
    navigator.clipboard.writeText(link);
    toast.success("Link copied to clipboard", "success");
  };

  render() {
    const { show, createdDesposit, handleOnCompleteGeneratedLink, isMobile } = this.props;

    return (
      <div>
        <Dialog
          open={show}
          fullScreen={isMobile}
          fullWidth={true}
          maxWidth="md"
          onClose={handleOnCompleteGeneratedLink}
        >
          <DialogTitle>New Transaction Link Created</DialogTitle>
          <DialogContent dividers>
            <div className={styles["content"]}>
              <div className={styles["success-img"]}>
                <img src="/img/success.svg" alt="success" />
              </div>
              <div className={styles["detail"]}>
                <div>{createdDesposit?.oneClickCheckout?.name}</div>
                <div>
                  {Convert.toCapitalizeLetters(
                    createdDesposit?.oneClickCheckout?.amcName
                  )}
                </div>
                <div>
                  {Convert.toCurrencyValue(
                    createdDesposit?.oneClickCheckout?.amount
                  )}{" "}
                </div>
              </div>
              <div className={styles["link"]}>
                <a href="#">{createdDesposit?.oneClickCheckout?.shortLink}</a>
              </div>
              <div
                className={styles["copy-link"]}
                onClick={() =>
                  this.onCopyLink({
                    link: createdDesposit?.oneClickCheckout?.shortLink,
                  })
                }
              >
                <img src="/img/copylink.svg" alt="copylink" />← Please click
                to copy this transaction
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOnCompleteGeneratedLink}>
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

import { useMediaQuery, useTheme } from '@mui/material';

const withMobileDialog = (Component) => {
  const WithMobileDialog = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return <Component {...props} isMobile={isMobile} />;
  };
  return WithMobileDialog;
};
export default withMobileDialog(GeneratedLink);

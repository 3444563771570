import React, { Component } from "react";
import { Button, Modal, Container, Divider } from "@mui/material";
import { Formik, Form } from "formik";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import RecommendationTable from "./recommendation-table";
import AddRecommendationDialog from "../add-recommendation-dialog";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogTitle, DialogContent, Avatar, Typography,
  Stack, IconButton, DialogActions } from '@mui/material'
import { deepOrange } from '@mui/material/colors'

class SeeAllRecommendation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewRecommendationDialogOpen: this.props.isDialogOpen,
    };
  }

  handleNewRecommendationOpen = () => {
    this.setState({ isNewRecommendationDialogOpen: !this.state.isNewRecommendationDialogOpen });
  };

  handleNewRecommendationClose = () => {
    this.setState({ isNewRecommendationDialogOpen: !this.state.isNewRecommendationDialogOpen });
  };

  render() {
    const { isOpen, onClose, lead,openSingleGraph } = this.props;
    let { fullName, leadRecommendations } = (lead || {})
    fullName ||= ""; leadRecommendations ||= []

    return (
      <>
        <Dialog
          open={isOpen}
          onClose={onClose}
          fullWidth={true}
          maxWidth="lg">
          <DialogTitle>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Avatar sx={{ bgcolor: deepOrange[500] }}>{fullName[0]}</Avatar>
              <span style={{marginLeft: 8}}>Recommendations for {fullName}</span>
            </div>
          </DialogTitle>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent dividers>
            <RecommendationTable  openSingleGraph={openSingleGraph} recommendations={leadRecommendations} />
          </DialogContent>

          <DialogActions>
            <Button style={{margin: 8}}
              variant="contained" startIcon={<AddIcon />}
              onClick={this.handleNewRecommendationOpen}>
              Add Recommendations
            </Button>
          </DialogActions>
        </Dialog>

        {
          this.state.isNewRecommendationDialogOpen &&
            <AddRecommendationDialog
              name={fullName}
              id={lead?.id}
              isOpenDialog={this.state.isNewRecommendationDialogOpen}
              onClose={this.handleNewRecommendationClose}
            />
        }
      </>
    );
  }
}

export default SeeAllRecommendation;

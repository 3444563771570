import { connect } from "react-redux";
import {
  requestCreateLandingPage,
  requestUpdateLandingPage,
} from "src/redux/actions";
import LandingPagesCreate from "./landing-pages";

const mapStateToProps = (state) => ({
  landingPages: state.landingPages,
});

export default connect(mapStateToProps, {
  requestCreateLandingPage,
  requestUpdateLandingPage,
})(LandingPagesCreate);

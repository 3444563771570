import {
  faChevronDown,
  faChevronUp,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Collapse from '@mui/material/Collapse';
import cx from "classnames";
import { Link, useLocation } from "react-router-dom";
import styles from "./_listitem.module.scss";
import { useDispatch } from 'react-redux'
import {requestToggleMobileSideMenu} from "src/redux/actions";

function ListItem({ item, keyIndex, isBorder, rightIcon, iconImg, leftIcon }) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [childToggleKey, setChildToggleKey] = useState(pathname);

  const dismissMenu = () => {
    dispatch(requestToggleMobileSideMenu())
  }

  let children = null;
  if (item?.values && item.values.length > 0) {
    children = (
      <Collapse
        className={styles.childMenu}
        in={!!childToggleKey.includes(item.toggleKey) || item?.isParentNav}
      >
        <ul>
          {item.values.map((i) => (
            <ListItem leftIcon item={i} key={i.id} />
          ))}
        </ul>
      </Collapse>
    );
  }

  return (
    <li>
      {item?.nav ? (
        <>
          {item?.isExernalLink ? (
            <a
              href={item?.nav}
              target="_blank"
              rel="noreferrer"
              className={cx(
                `${styles.linkItem} ${pathname === item.nav && styles.active}`,
                {
                  [styles["first-list-anchor"]]: keyIndex === 0,
                  [styles["link-border"]]: isBorder,
                }
              )}
            >
              <div className={styles["icon-container"]}>
                {leftIcon && (
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className={styles.icon}
                  />
                )}
                {item.name}
              </div>
              {rightIcon && (
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className={styles.icon}
                />
              )}
            </a>
          ) : (
            <Link
              onClick={dismissMenu}
              to={item.nav}
              className={cx(
                `${styles.linkItem} ${pathname === item.nav && styles.active}`,
                {
                  [styles["first-list-anchor"]]: keyIndex === 0,
                  [styles["link-border"]]: isBorder,
                }
              )}
              style={{'color': process.env.REACT_APP_SIDEBAR_TEXT_COLOR || '#fff'}}
            >
              <div>
                {leftIcon && (
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className={styles.icon}
                    style={{'color': process.env.REACT_APP_SIDEBAR_TEXT_COLOR || '#fff'}}
                  />
                )}
                {item.name}
              </div>
              {rightIcon && (
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className={styles.icon}
                  style={{'color': process.env.REACT_APP_SIDEBAR_TEXT_COLOR || '#fff'}}
                />
              )}
            </Link>
          )}
        </>
      ) : (
        <>
          {item?.isParentNav ? (
            <>
              <Link
                onClick={dismissMenu}
                to={item.toggleKey}
                className={cx(
                  `${styles.linkItem} ${
                    pathname === item.toggleKey && styles.active
                  }`,
                  {
                    [styles["first-list-anchor"]]: keyIndex === 0,
                    [styles["link-border"]]: isBorder,
                  }
                )}
                style={{'color': process.env.REACT_APP_SIDEBAR_TEXT_COLOR || '#fff'}}
              >
                <div>
                  {leftIcon && (
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className={styles.icon}
                      style={{'color': process.env.REACT_APP_SIDEBAR_TEXT_COLOR || '#fff'}}
                    />
                  )}
                  {item.name}
                </div>
                {rightIcon && (
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className={styles.icon}
                    style={{'color': process.env.REACT_APP_SIDEBAR_TEXT_COLOR || '#fff'}}
                  />
                )}
              </Link>
            </>
          ) : (
            <a
              href=""
              className={cx(
                `${styles.listItemContainer} ${
                  pathname.includes(item.toggleKey) && styles.active
                }`,
                {
                  [styles["first-list-anchor"]]: keyIndex === 0,
                  [styles["link-border"]]: isBorder,
                }
              )}
            >
              <span className={styles.listItem}>{item.name} </span>
              {childToggleKey.includes(item.toggleKey) ? (
                <FontAwesomeIcon icon={faChevronUp} className={styles.icon} />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} className={styles.icon} />
              )}
            </a>
          )}
        </>
      )}
      {children}
    </li>
  );
}

export default ListItem;

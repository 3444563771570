import { connect } from "react-redux";
import { requestAdminShowDeposit } from "src/redux/actions";
import ReadModal from "./read-modal";

const mapStateToProps = (state) => ({
  showDeposit: state.adminDeposits?.showDeposit,
});

export default connect(mapStateToProps, {
  requestAdminShowDeposit,
})(ReadModal);

import React, { useMemo, useState } from "react";
import moment from "moment";
import { Convert } from "src/helpers";
import { Badge } from "src/components/theme";
import { DataTable, TextOne, LinkOne } from "../../../../../components";
import styles from "./_generic-links-table.module.scss";
import ReadModal from "./read-modal";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import _ from 'lodash';

const API_ENDPOINT_URL = process.env.API_ENDPOINT_URL || "https://surface.thesavvyapp.in";

const GenericLinksTable = (props) => {
  const [selectedRowData, setSelectedRowData] = useState(null);

  const tableStyles = {
    bodyRow: {
      backgroundColor: "#ffffff",
      borderBottomColor: "#f3f3f3",
    },
    tableHeader: {
      backgroundColor: "#ffffff",
      borderTop: "1px solid #F3F3F3",
      borderBottom: "1px solid #F3F3F3",
    },
  };

  const { totalItems, data, handlePaginationChange } = props;

  const pageSize = 50;

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };

  const columns = useMemo(
    () => [
      {
        Header: <strong>Name</strong>,
        accessor: "nickname",
        disableSortBy: true,
        Cell: ({ row, value }) => (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setSelectedRowData(row)}
          >
            {value}
          </div>
        ),
      },
      {
        Header: <strong>Scheme(s)</strong>,
        accessor: "funds",
        id: "fundName",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setSelectedRowData(row)}
            >
              {_.isEmpty(value) && "All schemes"}
              {!_.isEmpty(value) && `${_.size(value)} schemes`
              }
            </div>
          );
        },
      },
      {
        Header: <strong>ID</strong>,
        accessor: "uuid",
        id: "uuid",
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setSelectedRowData(row)}
            >
              {value}
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        tableStyles={tableStyles}
        paginationConfig={paginationConfig}
        handlePaginationChange={handlePaginationChange}
      />
      {selectedRowData?.uuid && (
        <ReadModal
          show={selectedRowData?.uuid}
          data={selectedRowData}
          handleClose={() => setSelectedRowData(null)}
        />
      )}
    </>
  )
}

export default GenericLinksTable;

import { ACTION_TYPES } from "src/constants";

export function requestAdminWebhookList(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_WEBHOOKS,
    payload,
  };
}

export function requestAdminWebhookListSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_WEBHOOKS_SUCCESS,
    payload,
  };
}

export function requestAdminWebhookListFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_WEBHOOKS_FAILED,
    payload,
  };
}

export function requestAdminCurrentWebhook(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_CURRENT_WEBHOOKS,
    payload,
  };
}

export function requestAdminCurrentWebhookSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_CURRENT_WEBHOOKS_SUCCESS,
    payload,
  };
}

export function requestAdminCurrentWebhookFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_CURRENT_WEBHOOKS_FAILED,
    payload,
  };
}

export function requestAdminUpdateWebhook(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_UPDATE_WEBHOOKS,
    payload,
  };
}

export function requestAdminUpdateWebhookSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_UPDATE_WEBHOOKS_SUCCESS,
    payload,
  };
}

export function requestAdminUpdateWebhookFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_UPDATE_WEBHOOKS_FAILED,
    payload,
  };
}

import { connect } from 'react-redux';
import CreateLead from './create-lead';
import {
  requestCreateLead,
  requestRiskProfiles,
} from "src/redux/actions";

const mapStateToProps = (state) =>  { 
  return{
    riskProfiles: state.riskProfiles,
    adminFunds: state.adminFunds,
  }
}

export default connect(mapStateToProps, { requestCreateLead, requestRiskProfiles, })(CreateLead);
import React, { useState } from 'react';
import { TextField, Button, Select, MenuItem, InputLabel,
  FormControl, Box, Grid, Stack } from '@mui/material';

const TemplateField = (props) => {
  const [template, setTemplate] = useState('');
  const [selectedVariable, setSelectedVariable] = useState('');

  const handleChange = (event) => {
    setTemplate(event.target.value);
    props.onChange && props.onChange(event.target.value)
  };

  const handleInsertVariable = () => {
    if (selectedVariable) {
      setTemplate((prevTemplate) => prevTemplate + `$\{\{${selectedVariable}\}}`);
      setSelectedVariable('');
    }
  };

  const handleVariableSelect = (event) => {
    setSelectedVariable(event.target.value);
  };

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={7}>
        <TextField
          label="Template"
          multiline
          rows={4}
          value={template}
          onChange={handleChange}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={5}>
        <Stack>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel>Select Variable</InputLabel>
            <Select
              value={selectedVariable}
              onChange={handleVariableSelect}
              label="Select Variable"
            >
              { _.map(props.variables, (variable) => {
                return (
                  <MenuItem value={variable['value']}>{variable['label']}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <Box marginTop={1}>
            <Button size="small" variant="outlined" onClick={handleInsertVariable}>Insert Variable</Button>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default TemplateField;

import React, { Component } from "react";
import Grid from '@mui/material/Grid';
import { Formik, Form } from "formik";
import { InputSwitch } from "src/components/form-inputs";
import { Container, Header } from "../../../../components";
import { Helpers } from "../../../../helpers";
import styles from "./_developers.module.scss";

class Developers extends Component {
  render() {
    return (
      <div className={styles["developers"]}>
        <Header title="Developers Overview" />
        <Container>
          <div className={styles["api-key-box"]}>
            <div className={styles["api-key-title"]}>API Keys</div>
            <div>
              <Formik
                initialValues={{
                  is_api_key: false,
                }}
              >
                <Form>
                  <InputSwitch
                    // val={`${values.is_api_key ? 'Yes' : 'No'}`}
                    name="is_sandbox_mode"
                    id="is_sandbox_mode"
                    labelPosition="right"
                    onClick={Helpers.switchMode}
                    checked={this.props.sandboxToggle}
                  />
                </Form>
              </Formik>
            </div>
            <div className={styles["api-key-desc"]}>
              You are viewing {this.props.sandboxToggle ? "sandbox" : "live"}{" "}
              keys. Toggle to view{" "}
              {this.props.sandboxToggle ? "live" : "sandbox"} keys
            </div>
          </div>
          <div className={styles["content"]}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className={styles["content-box-cover"]}>
                  <div className={styles["content-box"]}>
                    <div className={styles["content-title"]}>Connect APIs</div>
                    <div className={styles["content-desc"]}>
                      Test API or read documentation
                    </div>
                    <div className={styles["content-data"]}>
                      <button className={styles["postman-run-btn"]}>
                        <img src="/img/playicon.svg" alt="play" />
                        Run in postman
                      </button>
                    </div>
                    <button className={styles["footer-btn"]}>Read docs</button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className={styles["content-box-cover"]}>
                  <div className={styles["content-box"]}>
                    {" "}
                    <div className={styles["content-title"]}>Logs</div>
                    <div className={styles["content-desc"]}>
                      Debug post logs here
                    </div>
                    <div className={styles["content-data"]}>
                      <div className={styles["content-number"]}>0</div>
                      <div className={styles["content-note"]}>
                        Calls made today
                      </div>
                    </div>
                    <button className={styles["footer-btn"]}>
                      Manage logs
                    </button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className={styles["content-box-cover"]}>
                  <div className={styles["content-box"]}>
                    {" "}
                    <div className={styles["content-title"]}>Webhooks</div>
                    <div className={styles["content-desc"]}>
                      View event logs
                    </div>
                    <div className={styles["content-data"]}>
                      <div className={styles["content-number"]}>0</div>
                      <div className={styles["content-note"]}>
                        No events sent
                      </div>
                    </div>
                    <button className={styles["footer-btn"]}>
                      Manage Webhooks
                    </button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    );
  }
}

export default Developers;
